import { quadraticFormula, sq } from "../util/math";
import { findMin, forEachPair } from "../util/arrays";
import { Location, Rectangle } from "../util/types";
import { Ball } from "./ball";
import { checkExhaustive } from "../util/assert";
import { PageTransformer } from "./page_transformer";
import { Circle, CollisionTarget, HorizontalLine, isLine, Line, VerticalLine } from "./collidable";
import { getById, setCssProperty } from "../util/dom";

export type Collidable = Ball|PageTransformer|VerticalLine|HorizontalLine;

export interface Collision {
  ball: Ball;
  target: CollisionTarget;
  loc: Location;
  dt: DOMHighResTimeStamp;
}

export function findNextCollisions(collidables: Collidable[], timeWindow: DOMHighResTimeStamp): Collision[] {
  const collisions: Collision[] = [];
  forEachPair(collidables, (obj1: Collidable, obj2: Collidable) => {
    let collision: Collision|undefined = undefined;
    if (obj1 instanceof Ball) {
      collision = findCollision(timeWindow, obj1, obj2)
    } else if (obj2 instanceof Ball) {
      collision = findCollision(timeWindow, obj2, obj1);
    }
    if (collision) {
      collisions.push(collision);
    }
  });
  return collisions;
}


function findCollision(timeWindow: DOMHighResTimeStamp, obj1: Ball, obj2: Collidable): Collision | undefined {
  if (obj2 instanceof Ball) {
    return findCircleCollision(timeWindow, obj1, obj2);
  } else if (obj2 instanceof PageTransformer) {
    return findPageTransformerCollision(timeWindow, obj1, obj2);
  } else if (isLine(obj2)) {
    return findLineCollision(timeWindow, obj1, obj2);
  } else {
    checkExhaustive(obj2);
  }
}

function findCircleCollision(timeWindow: DOMHighResTimeStamp, obj1: Ball, obj2: Circle): Collision | undefined {
  if (!obj1.isMoving() && !obj2.dx && !obj2.dy && !obj2.dradius) return;

  const dt = findCircleCollisionTime(timeWindow, obj1, obj2);
  if (dt === undefined) {
    return;
  }

  // Move up to collision.
  const obj1Center = getFutureCircleCenter(obj1, dt);
  const obj2Center = getFutureCircleCenter(obj2, dt);

  const obj1Radius = getFutureBallRadius(obj1, dt);
  const obj2Radius = getFutureBallRadius(obj2, dt);

  const myRatio =  obj1Radius / (obj1Radius + obj2Radius);
  return {
    dt,
    loc: {
      x: obj1Center.x + (obj2Center.x - obj1Center.x) * myRatio,
      y: obj1Center.y + (obj2Center.y - obj1Center.y) * myRatio,
    },
    ball: obj1,
    target: obj2,
  }
}

function getFutureCircleCenter(circle: Circle, dt: DOMHighResTimeStamp): Location {
  return {x: circle.x + circle.dx * dt, y: circle.y + circle.dy * dt};
}

function getFutureBallRadius(ball: Circle, dt: DOMHighResTimeStamp): number {
  return ball.radius + ball.dradius * dt;
}

function findCircleCollisionTime(timeWindow: DOMHighResTimeStamp, obj1: Ball, obj2: Circle): undefined|DOMHighResTimeStamp {
  // This math below is solving for T in:
  // 0 = ((obj1.x + obj1.dx + t) - (obj2.x + obj2.dx + t))^2 + ((obj1.y + obj1.dy + t) - (obj2.y + obj2.dy + t))^2 - ((obj1.radius * obj1.dradius * t) + (obj2.radius * obj2.dradius * t))^2
  const a = sq(obj1.dx) - 2 * obj1.dx * obj2.dx + sq(obj2.dx) + sq(obj1.dy) - 2 * obj1.dy * obj2.dy + sq(obj2.dy) - sq(obj1.dradius) - 2 * obj1.dradius * obj2.dradius - sq(obj2.dradius);
  const b = 2 * obj1.x * obj1.dx - 2 * obj1.x * obj2.dx - 2 * obj1.dx * obj2.x + 2 * obj2.x * obj2.dx + 2 * obj1.y * obj1.dy - 2 * obj1.y * obj2.dy - 2 * obj1.dy * obj2.y + 2 * obj2.y * obj2.dy - 2 * obj1.radius * obj1.dradius - 2 * obj1.radius * obj2.dradius - 2 * obj1.dradius * obj2.radius - 2 * obj2.radius * obj2.dradius;
  const c = sq(obj1.x) - 2 * obj1.x * obj2.x + sq(obj2.x) + sq(obj1.y) - 2 * obj1.y * obj2.y + sq(obj2.y) - sq(obj1.radius) - 2 * obj1.radius * obj2.radius - sq(obj2.radius);
  const times = quadraticFormula(a, b, c);
  if (!times) return;
  return findMinValidTime(times, timeWindow);
}

function findRectangleCollision(timeWindow: DOMHighResTimeStamp, ball: Ball, lines: Line[], corners: Circle[]): Collision | undefined {     
  const collisions: Array<Collision|undefined> = [];

  for (const line of lines) {
    collisions.push(findLineCollision(timeWindow, ball, line));
  }

  for (const corner of corners) {
    collisions.push(findCircleCollision(timeWindow, ball, corner));
  }

  return findMin(collisions, c => c.dt);
}

function findPageTransformerCollision(timeWindow: DOMHighResTimeStamp, ball: Ball, transformer: PageTransformer): Collision|undefined {
  if (transformer.isComplete) {
    return findRectangleCollision(timeWindow, ball, transformer.lines, transformer.cornerCircles);
  }

  const collisions: Array<Collision|undefined> = [];

  const lines = transformer.lines;
  for (const line of lines) {
    collisions.push(findLineCollision(timeWindow, ball, line));
  }
  
  // We don't have to check the line's corners because they are covered by the sphere below.
  
  // Check the sphericle part of the PageTransformer.
  let circleCollision = findCircleCollision(timeWindow, ball, transformer.circle);

  if (circleCollision && circleCollision.dt > timeWindow) {
    // This can happen when shrinking and the ball is sitting outside of the circle.
    circleCollision = undefined;
  }

  if (circleCollision) {
    // Check to see if the collision was in the circle part that's actually outside of the rectangle.
    const {loc} = circleCollision;
    const rect = transformer.rectangle;
    if (loc.x < rect.left || loc.x > rect.right || loc.y < rect.top || loc.y > rect.bottom) {
      circleCollision = undefined;
    }
  }
  collisions.push(circleCollision);

  return findMin(collisions, c => c.dt);
}

function findLineCollision(timeWindow: DOMHighResTimeStamp, ball: Ball, line: Line): Collision|undefined {
  const type = line.type;
  switch (type) {
    case 'vertical-line':
      return findVerticalLineCollision(timeWindow, ball, line);
    case 'horizontal-line':
      return findHorizontalLineCollision(timeWindow, ball, line);
    default:
      checkExhaustive(type);
  }
}

/**
 * Note: this doesn't actually match on the corner of a line.
 * To match on corners, you first try the horizontal/vertical lines,
 * then separately test corners with locationToCircle().
 * This is to prevent redundant collision checking.
 */
function findHorizontalLineCollision(timeWindow: DOMHighResTimeStamp, ball: Ball, line: HorizontalLine): Collision|undefined {
  if (!ball.dy && !ball.dradius) return;
  let times: number[] = [];
  {
    const dist = (line.y - ball.top);
    const velocity = (ball.dy - ball.dradius);
    // If we're on the ball, we need to be able to move away from it.
    if (velocity < 0) {
      times.push(dist / velocity);
    }
  }
  {
    const dist = (line.y - ball.bottom);
    const velocity = (ball.dy + ball.dradius);
    // If we're on the ball, we need to be able to move away from it.
    if (velocity > 0) {
      times.push(dist / velocity);
    }
  }
  const dt = findMinValidTime(times, timeWindow);
  if (dt === undefined) return;
  const {x} = getFutureCircleCenter(ball, dt);
  if (x < line.left + line.dleft * dt) return;
  if (x > line.right + line.dright * dt) return;
  return {
    ball,
    target: line,
    loc: {
      x,
      y: line.y,
    },
    dt,
  };
}

/**
 * Note: this doesn't actually match on the corner of a line.
 * To match on corners, you first try the horizontal/vertical lines,
 * then separately test corners with locationToCircle().
 * This is to prevent redundant collision checking.
 */
function findVerticalLineCollision(timeWindow: DOMHighResTimeStamp, ball: Ball, line: VerticalLine): Collision|undefined {
  if (!ball.dx && !ball.dradius) return;
  let times: number[] = [];
  {
    const dist = (line.x - ball.left);
    const velocity = (ball.dx - ball.dradius);
    if (velocity < 0) {
      times.push(dist / velocity);
    }
  }
  {
    const dist = (line.x - ball.right);
    const velocity = (ball.dx + ball.dradius);
    if (velocity > 0) {
      times.push(dist / velocity);
    }
  }
  const dt = findMinValidTime(times, timeWindow);
  if (dt === undefined) return;
  const {y} = getFutureCircleCenter(ball, dt);
  if (y < line.top + line.dtop * dt) return;
  if (y > line.bottom + line.dbottom * dt) return;
  return {
    ball,
    target: line,
    loc: {
      x: line.x,
      y,
    },
    dt,
  };
}

function findMinValidTime(times: DOMHighResTimeStamp[], timeWindow: DOMHighResTimeStamp): DOMHighResTimeStamp|undefined {
  times = times.filter(t => t <= timeWindow && t >= 0);
  return findMin(times, t => t);
}

// Debugging rendering
function renderLines(lines: Line[]) {
  for (let i = 0; i < lines.length; i++) {
    const line = lines[i];
    const el = getById('line-' + i);
    if (line.type === 'vertical-line') {
      el.style.width = '1px';
      el.style.height = `${line.bottom - line.top}px`;
      el.style.top = `${line.top}px`;
      el.style.left = `${line.x}px`;
    } else {
      el.style.height = '1px';
      el.style.width = `${line.right - line.left}px`;
      el.style.left = `${line.left}px`;
      el.style.top = `${line.y}px`;
    }
  }
}

function renderCircles(circle: Circle[]) {
  for (let i = 0; i < circle.length; i++) {
    const corner = circle[i];
    const el = getById('circle-' + i);
    setCssProperty(el, '--radius', `${corner.radius}px`);
    setCssProperty(el, '--x', `${corner.x}px`);
    setCssProperty(el, '--y', `${corner.y}px`);
  }
}