import { addMouseDownListener, addMouseMoveListener, addMouseUpListener, getMouseEventOrTouch, getPositionAndTime, isTouchEvent, transform } from "../util/dom";
import { LocationAndTime, Location } from "../util/types";
import { CircularArray } from "../util/circular_array";
import { Circle } from "./collidable";
import { Vector } from "./vector";
import { diffAngles, normalizeRad, rad2deg, sign, valueAlongScale, clamp, sq, randomFloat } from "../util/math";
import { Page, PAGE_CONFIGS } from "./page";
import { addScreenSizeListener, getBallRadius, getEnvironmentState } from "./environment";
import { ParticlePool, ThrustParticle } from "./particle";

const SLIDE_FRICTION = 0.0006;

export const MIN_SPEED = 0.01;

const NUM_PARTICLES = 35;

export enum BallShrinkDirection {
  SHRINKING,
  GROWING,
}

enum DrivingState {
  ACCELERATING = 'ACCELERATING',
  COASTING = 'COASTING',
  BRAKING = 'BRAKING',
}

const MINIMUM_WAIT_BETWEEN_IDLE_THRUSTS = 700;

/** The distance at which we decide it's close enough and swith to the page transition. */
const DRIVING_COMPLETE_DISTANCE_SQ = sq(2);

const GRAVITY_AMOUNT = 0.0038;

export class Ball implements Circle {
  readonly type = 'circle';
  private readonly el: HTMLElement;

  moveable = true;

  bigRadius: number;
  smallRadius: number;

  private maxSpeed: number;
  private maxDrivingSpeed: number;
  private thrustAccelerationRate: number;
  private initialBrakingDistance: number;
  private brakeAccelerationRate: number;

  // Assume this will get initialized immediately after construction.
  centerX = 0;
  centerY = 0;

  dx = 0;
  dy = 0;
  dradius = 0;
  radius: number;
  radiusSquared: number;
  unmoveableX = 0;
  unmoveableY = 0;

  // When set, clear dx and dy on endFrame
  clearDOnEndFrame = false;

  ballShrinkDirection: BallShrinkDirection|null = null;
  ballShrinkDuration: DOMHighResTimeStamp = 0;
  ballShrinkTimePassed: DOMHighResTimeStamp = 0;

  prevGrabs = new CircularArray<LocationAndTime>(2);
  isGrabbed = false;
  touchId: number|undefined = undefined;
  // The amount of movement that has been measured since last applied to dx,dy.
  mouseDrag = {x: 0, y: 0};
  // The distance from center that the mouse is positioned.
  mouseGrabOffset = {x: 0, y: 0};
  // Measured after every render. The distance the mouse currently is from mouseGrabOffset.
  mousePlacementLoss = {x: 0, y: 0};
  grabSinceLastRender: LocationAndTime|null = null;
  
  private drivingToCenter = false;
  private drivingState = DrivingState.ACCELERATING;

  private idleThrustAngle: number = 0;
  private idleThrustTimeRemaining = 0;

  disabled = false;
  gravityEnabled = false;

  particles = new ParticlePool(NUM_PARTICLES, () => new ThrustParticle(PAGE_CONFIGS[this.name].hue));

  constructor(readonly name: Page) {
    const ballEl = document.querySelector(`.ball[data-page="${name}"]`);
    if (!ballEl) throw 'Could not find ballEl';
    this.el = ballEl as HTMLElement;

    this.setScreenSizeAdjustedProps();
    addScreenSizeListener(this.setScreenSizeAdjustedProps.bind(this));

    this.setRadius(this.bigRadius);

    addMouseDownListener(this.el, (event: MouseEvent|TouchEvent) => {
      if (this.isGrabbed || this.disabled) return;
      event.preventDefault();
      this.moveable = false;
      this.isGrabbed = true;

      this.idleThrustTimeRemaining = 0;

      this.dx = 0;
      this.dy = 0;

      const touch = isTouchEvent(event) ? event.targetTouches[0] : null;
      this.touchId = touch?.identifier;

      const pos = getPositionAndTime(touch ?? (event as MouseEvent), event)!;
      this.mouseGrabOffset.x = pos.x - this.centerX;
      this.mouseGrabOffset.y = pos.y - this.centerY;
      this.mousePlacementLoss.x = 0;
      this.mousePlacementLoss.y = 0;

      this.prevGrabs.clear();
      this.prevGrabs.push(pos);
      this.grabSinceLastRender = pos;
    });

    addMouseMoveListener(document, (event: MouseEvent|TouchEvent) => {
      if (!this.isGrabbed || this.disabled) return;

      const eventOrTouch = getMouseEventOrTouch(event, this.touchId);
      if (!eventOrTouch) return;
      const pos = getPositionAndTime(eventOrTouch, event);
      
      const prevPos = this.prevGrabs.last()!;

      this.mouseDrag.x += pos.x - prevPos.x;
      this.mouseDrag.y += pos.y - prevPos.y;

      this.prevGrabs.push(pos);
      this.grabSinceLastRender = pos;
    });

    addMouseUpListener(document, event => {
      if (!this.isGrabbed || this.disabled) return;

      const eventOrTouch = getMouseEventOrTouch(event, this.touchId);
      if (!eventOrTouch) return;
      const pos = getPositionAndTime(eventOrTouch, event);

      event.preventDefault();

      const prevPos = this.prevGrabs.first()!;

      let dt = pos.time - prevPos.time;
      if (dt === 0) {
        this.dx = 0;
        this.dy = 0;
      } else {
        this.dx = (pos.x - prevPos.x) / dt;
        this.dy = (pos.y - prevPos.y) / dt;
      }

      this.applyFriction(dt);

      this.isGrabbed = false;
      this.moveable = true;
      this.prevGrabs.clear();
      this.grabSinceLastRender = null;
    });
  }

  private setScreenSizeAdjustedProps() {
    const radiuses = getBallRadius(PAGE_CONFIGS[this.name]);
    this.smallRadius = radiuses.small;
    this.bigRadius = radiuses.big;

    const scaler = clamp(getEnvironmentState().screenSize.diagnol / 1000, 0.7, 2);
    this.maxSpeed = 3 * scaler;
    this.maxDrivingSpeed = 0.6 * scaler;
    this.thrustAccelerationRate = 0.003 * scaler;
    this.initialBrakingDistance = 80 * scaler;
    this.brakeAccelerationRate = 0.02 * scaler;

    if (this.gravityEnabled) {
      this.thrustAccelerationRate += GRAVITY_AMOUNT;
      this.brakeAccelerationRate *= 0.5;
    }
  }

  initFrame(dt: DOMHighResTimeStamp) {
    if (this.disabled) return;
    if (this.isGrabbed) {
      // If the mouse is currently offset from where it started on the ball, then
      // don't accept any movement in that direction until we've recovered the
      // mouse position. This can happen when sliding the ball against a wall
      // or other object that prevents movement.
      if (this.mousePlacementLoss.x) {
        const dragDirection = sign(this.mouseDrag.x);
        if (dragDirection === sign(this.mousePlacementLoss.x) * -1) {
          this.mouseDrag.x += this.mousePlacementLoss.x;
          if (sign(this.mouseDrag.x) !== dragDirection) {
            this.mouseDrag.x = 0;
          }
        }
      }
      if (this.mousePlacementLoss.y) {
        const dragDirection = sign(this.mouseDrag.y);
        if (dragDirection === sign(this.mousePlacementLoss.y) * -1) {
          this.mouseDrag.y += this.mousePlacementLoss.y;
          if (sign(this.mouseDrag.y) !== dragDirection) {
            this.mouseDrag.y = 0;
          }
        }
      }

      this.dx = this.mouseDrag.x / dt;
      this.dy = this.mouseDrag.y / dt;
      this.validateNotNaN(0, 'dx', 'dy');
      
      this.mouseDrag.x = 0;
      this.mouseDrag.y = 0;
    }

    this.dradius = this.calculateDRadius(dt);

    this.applyDriveThrusters(dt);

    if (this.idleThrustTimeRemaining > 0) {
      const thrust = new Vector(0, 0);
      thrust.angle = this.idleThrustAngle;
      const v = this.thrustAccelerationRate * (this.gravityEnabled ? 1 : 0.5);
      thrust.velocity = v * dt;
      this.applyThrust(thrust, dt);
    }
    this.idleThrustTimeRemaining -= dt;
  }

  endFrame(dt: DOMHighResTimeStamp) {
    if (this.disabled) return;

    if (this.gravityEnabled) {
      if (this.unmoveableY <= 0) {
        this.dy += dt * GRAVITY_AMOUNT;
      } else {
        this.dy = 0;
      }
    }

    if (this.clearDOnEndFrame) {
      this.clearDOnEndFrame = false;
      this.dx = 0;
      this.dy = 0;
    } else if (this.isGrabbed) {
      this.dx = 0;
      this.dy = 0;
    } else if (this.dx !== 0 || this.dy !== 0) {
      this.applyFriction(dt);
    }
  }

  toggleGravity(enabled: boolean) {
    this.gravityEnabled = enabled;
    this.setScreenSizeAdjustedProps();
  }

  driveToCenter() {
    this.idleThrustTimeRemaining = 0;

    this.drivingToCenter = true;
    this.drivingState = DrivingState.ACCELERATING;
  }

  cancelDrive() {
    this.idleThrustTimeRemaining = 0;

    this.drivingToCenter = false;
  }

  private get drivingTo(): Location|null {
    return this.drivingToCenter ? getEnvironmentState().screenCenter : null;
  }

  applyRandomThrust(dt: DOMHighResTimeStamp) {
    if (this.isGrabbed || this.idleThrustTimeRemaining > MINIMUM_WAIT_BETWEEN_IDLE_THRUSTS) return;
    const chance = this.gravityEnabled ? 0.023 : 0.075;
    if (Math.random() * dt > chance) return;
    
    const {screenCenter} = getEnvironmentState();

    if (this.gravityEnabled) {
      this.idleThrustAngle = Math.PI * -0.5;
      this.idleThrustAngle += randomFloat(-0.1, 0.3) * (this.x < screenCenter.x ? 1 : -1);
      this.idleThrustTimeRemaining = randomFloat(200, 700); 
    } else {
      const toCenter = new Vector(screenCenter.x - this.x, screenCenter.y - this.y);
      this.idleThrustAngle = toCenter.angle + randomFloat(-0.5, 0.5);
      this.idleThrustTimeRemaining = randomFloat(100, 200); 
    }
  }

  private applyDriveThrusters(dt: DOMHighResTimeStamp) {
    const {drivingTo} = this;
    if (!drivingTo) return;

    const vector = new Vector(this.dx, this.dy);

    const dist = this.distanceFrom(drivingTo);
    const desiredAngle = Math.atan2(drivingTo.y - this.centerY, drivingTo.x - this.centerX);

    const diffAngle = diffAngles(vector.angle, desiredAngle);
 
    const isMovingAway = Math.abs(diffAngle) > Math.PI / 2;
    const desiredSpeed = clamp((dist/this.initialBrakingDistance) / this.maxDrivingSpeed, 0.15, this.maxDrivingSpeed);

    const thrust = new Vector(0, 0);

    const speed = Math.abs(vector.velocity);
    if (this.drivingState === DrivingState.ACCELERATING ||
        Math.abs(diffAngle) > 0.1 || speed < 0.1) {
      thrust.velocity = this.thrustAccelerationRate * dt;
      thrust.angle = desiredAngle;

      // Apply additional angle to correct for current direction.
      if (vector.velocity > 0) {
        let angleBoost = diffAngle * -1;
        if (isMovingAway) {
          angleBoost = diffAngles(normalizeRad(vector.angle + Math.PI), desiredAngle);
        }
        thrust.angle += angleBoost * (isMovingAway ? 0.8 : 0.5);
      }

      const newSpeed = speed + Math.abs(thrust.velocity);
      this.drivingState = newSpeed >= desiredSpeed ? DrivingState.COASTING : DrivingState.ACCELERATING;

      if (this.gravityEnabled && drivingTo.y > this.y) {
        // We're driving down, cut the thrust so that we don't way over shoot.
        thrust.velocity *= 0.5;
      }
    } else if (speed > desiredSpeed) {
      const brakingPercent = (speed - desiredSpeed) / this.maxDrivingSpeed;
      const brakeRate = Math.max(this.brakeAccelerationRate * brakingPercent, 0.002);
      thrust.velocity = brakeRate * dt;

      thrust.angle = normalizeRad(desiredAngle + Math.PI);
      thrust.angle += diffAngle * 0.3;

      this.drivingState = DrivingState.BRAKING;
    }

    this.applyThrust(thrust, dt);
  }

  private applyThrust(thrust: Vector, dt: DOMHighResTimeStamp) {
    const selfVector = new Vector(this.dx, this.dy);
    selfVector.x += thrust.x;
    selfVector.y += thrust.y;
    if (Math.abs(selfVector.velocity) > this.maxDrivingSpeed) {
      selfVector.velocity = this.maxDrivingSpeed;
    }

    this.spawnThrustParticles(thrust, dt);

    this.dx = selfVector.x;
    this.dy = selfVector.y;
  }

  private spawnThrustParticles(thrust: Vector, dt: DOMHighResTimeStamp) {
    const thrustPercent = Math.abs(thrust.velocity / (this.thrustAccelerationRate * dt));
    let numParticles = Math.round(thrustPercent * Math.random() * 4);
    if (!numParticles) return;

    thrust.velocity = clamp(0.00055 * getEnvironmentState().screenSize.diagnol, 0.43, 0.62);
    thrust.angle += Math.PI;
    thrust.angle += (Math.random() * 0.5 - 0.5) * 0.3;

    this.particles.spawn(numParticles, {
      x: this.x + (Math.cos(thrust.angle) * this.radius),
      y: this.y + (Math.sin(thrust.angle) * this.radius),
      dx: thrust.x * 1.8 + this.dx,
      dy: thrust.y * 1.8 + this.dy,
    });
  }

  isInside(loc: Location) {
    const xDiff = this.x - loc.x;
    const yDiff = this.y - loc.y;
    return xDiff * xDiff + yDiff * yDiff <= this.radiusSquared;
  }

  distanceFrom(other: Location) {
    return Math.sqrt(this.distanceFromSquared(other));
  }

  distanceFromSquared(other: Location) {
    const xDiff = this.x - other.x;
    const yDiff = this.y - other.y;
    return xDiff * xDiff + yDiff * yDiff;
  }

  get drivingComplete(): boolean {
    if (this.disabled || !this.drivingTo) true;
    return this.distanceFromSquared(this.drivingTo!) <= DRIVING_COMPLETE_DISTANCE_SQ;
  }

  setDisabled(disabled: boolean) {
    const drivingTo = this.drivingTo;
    if (disabled && drivingTo) {
      this.centerX = drivingTo.x;
      this.centerY = drivingTo.y;
    }

    this.disabled = disabled;
    this.dx = 0;
    this.dy = 0;
    this.cancelDrive();
  }

  /** Tick the position without any concern for collision. */
  move(dt: DOMHighResTimeStamp, frameTimeRemaining: DOMHighResTimeStamp) {
    if (this.disabled) return;

    this.centerX += this.dx * dt;
    this.centerY += this.dy * dt;

    this.setRadius(this.calculateNextRadius(dt));
    this.ballShrinkTimePassed += dt;
    this.dradius = this.calculateDRadius(frameTimeRemaining);

    if (this.unmoveableX && sign(this.dx) !== sign(this.unmoveableX)) {
      this.unmoveableX = this.unmoveableX + this.dx * dt;
      if (sign(this.dx) === sign(this.unmoveableX)) {
        this.unmoveableX = 0;
      }
    }
    if (this.unmoveableY && sign(this.dy) !== sign(this.unmoveableY)) {
      this.unmoveableY = this.unmoveableY + this.dy * dt;
      if (sign(this.dy) === sign(this.unmoveableY)) {
        this.unmoveableY = 0;
      }
    }
  }

  startShrinking(direction: BallShrinkDirection, duration: number) {
    if (this.isShrinking) {
      if (this.ballShrinkDirection === direction) return;
      const timePassedScaled = this.ballShrinkTimePassed * (duration / this.ballShrinkDuration);
      this.ballShrinkTimePassed = duration - timePassedScaled;
    } else {
      this.ballShrinkDuration = duration;
      this.ballShrinkTimePassed = 0;
    }
    this.ballShrinkDirection = direction;
  }

  get isShrinking(): boolean {
    return Boolean(this.ballShrinkDirection && this.ballShrinkTimePassed < this.ballShrinkDuration);
  }

  calculateDRadius(timeWindow: DOMHighResTimeStamp): number {
    if (this.ballShrinkTimePassed >= this.ballShrinkDuration) return 0;
    // This whole thing is pretty goofy, but it's to help deal with the fact
    // that the later we are in the timeWindow, the higher the speed will be.
    // This is because the growth is not linear. Grabbing the highest speed
    // makes all of the other physics smoother.
    const dt = 0.000001;
    let endTime: number;
    if (timeWindow === 0) {
      endTime = this.ballShrinkTimePassed + dt;
    } else if (this.ballShrinkTimePassed + timeWindow > this.ballShrinkDuration) {
      // We're going to finish during timeWindow.
      endTime = this.ballShrinkDuration;
    } else {
      endTime = this.ballShrinkTimePassed + timeWindow;
    }
    // I'm too lazy to figure out how to do this with calculus.
    const startRadius = this.calculateRadiusAtTimePassed(endTime - dt);
    const endRadius = this.calculateRadiusAtTimePassed(endTime);
    const actual = (endRadius - startRadius) / dt;
    // A really low value isn't helpful because it will always round to 0.
    if (actual > 0 && actual < 0.005) return 0.005;
    return actual;
  }

  private calculateNextRadius(dt: DOMHighResTimeStamp): number {
    return this.calculateRadiusAtTimePassed(this.ballShrinkTimePassed + dt);
  }

  private calculateRadiusAtTimePassed(ballShrinkTimePassed: DOMHighResTimeStamp): number {
    let percent = clamp(this.ballShrinkDuration ? ballShrinkTimePassed / this.ballShrinkDuration : 1, 0, 1);
    const isShrinking = this.ballShrinkDirection === BallShrinkDirection.SHRINKING;
    const from = isShrinking ? this.bigRadius : this.smallRadius;
    const to = isShrinking ? this.smallRadius : this.bigRadius;
    if (isShrinking) {
      percent = 1 - easeInQuint(1 - percent);
    } else {
      percent = easeInQuint(percent)
    }
    return valueAlongScale(from, to, percent);
  }

  private applyFriction(dt: DOMHighResTimeStamp) {
    if (this.disabled) return;
    if (this.drivingToCenter) return;
    const vector = new Vector(this.dx, this.dy);
    vector.velocity *= 1 - (SLIDE_FRICTION * dt);
    vector.velocity = Math.max(-1 * this.maxSpeed, Math.min(this.maxSpeed, vector.velocity));
    if (Math.abs(vector.velocity) < MIN_SPEED) {
      vector.velocity = 0;
    }
    this.dx = vector.x;
    this.dy = vector.y;
  }

  render() {
    if (this.disabled) {
      // If a resize happened, make sure we remain in the center;
      const {screenCenter} = getEnvironmentState();
      this.centerX = screenCenter.x;
      this.centerY = screenCenter.y;
    }

    this.el.style.setProperty('--ball-x-px', `${this.left}px`);
    this.el.style.setProperty('--ball-y-px', `${this.top}px`);
    this.el.style.setProperty('--ball-diameter', String(this.radius * 2));
    
    if (this.disabled) return;

    // Measure how far away the mouse placement is from the center
    // compared to when it was first grabbed.
    // This is used to account for when mouse movement is ignored due
    // to dragging against the screen edge or other frozen objects.
    if (this.grabSinceLastRender) {
      const pos = this.grabSinceLastRender;
      const offsetX = pos.x - this.centerX;
      const offsetY = pos.y - this.centerY;
      this.mousePlacementLoss.x = offsetX - this.mouseGrabOffset.x;
      this.mousePlacementLoss.y = offsetY - this.mouseGrabOffset.y;
    }
  }

  isMoving(): boolean {
    return Boolean(this.dx || this.dy || this.dradius);
  }

  setRadius(r: number) {
    this.radius = r;
    this.radiusSquared = r * r;
  }

  get left() {
    return this.centerX - this.radius;
  }

  set left(l) {
    this.centerX = l + this.radius;
  }

  get right() {
    return this.centerX + this.radius;
  }

  set right(r) {
    this.centerX = r - this.radius;
  }

  get top() {
    return this.centerY - this.radius;
  }

  set top(t) {
    this.centerY = t + this.radius;
  }

  get bottom() {
    return this.centerY + this.radius;
  }

  set bottom(b) {
    this.centerY = b - this.radius;
  }

  get x(): number {
    return this.centerX;
  }

  get y(): number {
    return this.centerY;
  }

  validateNotNaN(backup: 0, ...props: string[]) {
    for (const prop of props) {
      if (isNaN(this[prop])) {
        debugger;
        this[prop] = backup;
      }
    }
  }

  serialize() {
    return {
      name: this.name,
      centerX: this.centerX,
      centerY: this.centerY,
      radius: this.radius,
      dx: this.dx,
      dy: this.dy,
      dradius: this.dradius,
      ballShrinkTimePassed: this.ballShrinkTimePassed,
      ballShrinkDuration: this.ballShrinkDuration,
      ballShrinkDirection: this.ballShrinkDirection,
    }
  }
}

function easeInQuint(x: number): number {
  return x * x * x * x * x;
}