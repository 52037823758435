import {Engine} from './movement/engine';
import { Page } from './movement/page';
import { getById, querySelector } from './util/dom';

let engine: Engine;

async function init() {
  await document.fonts.ready;
  document.body.style.visibility = 'visible';

  registerContactFormHandler();
  registerLinkClickHandler();
  engine = new Engine();
  // Wait a frame before navigating to prevent jank.
  requestAnimationFrame(() => navigate());
}

function registerLinkClickHandler() {
  getById('gravity-link').addEventListener('click', () => {
    const el = getById('gravity-link');
    if (el.classList.contains('active')) {
      el.classList.remove('active');
      engine.toggleGravity(false);
    } else {
      el.classList.add('active');
      engine.toggleGravity(true);
    }
  });

  document.addEventListener('click', (e: MouseEvent) => {
    let link = e.target as HTMLElement|null|undefined;
    if (link?.tagName !== 'A') {
      link = link?.closest('a');
    }
    const href = link?.getAttribute('href');
    if (!href || !href.startsWith('/')) return;

    e.preventDefault();
    history.pushState(undefined, '', href);
    navigate(href);
  });

  addEventListener('popstate', () => {
    navigate();
  });
}

function navigate(url?: string) {
  if (!url) {
    url = location.pathname;
  }
  const page = Object.values(Page).find(p => p === url.substring(1)) ?? null;
  engine.navigate(page);

  const currentLink = document.querySelector('#header .current');
  currentLink?.classList.remove('current');
  for (const link of Array.from(document.querySelectorAll('#header a'))) {
    if (link.getAttribute('href') === url) {
      link.parentElement!.classList.add('current');
    }
  }
}

function registerContactFormHandler() {
  const formEl = getById('contact-form') as HTMLFormElement;
  formEl.addEventListener('submit', e => {
    e.preventDefault();
    if (isFormDisabled() || !validateContactForm()) {
      return;
    }

    showFormError('');
    setFormDisabled(true);
    function onSuccess() {
      setFormDisabled(false);
      formEl.style.height = formEl.offsetHeight + 'px';
      formEl.innerHTML = '<div id="sent-message">Your email has been sent.</div>'
    }

    function onError() {
      showFormError('Something went wrong. Please try again.');
      setFormDisabled(false);
    }
    postForm(formEl, onSuccess, onError);
  })
}

function isFormDisabled(): boolean {
  return (querySelector('#contact-form button') as HTMLButtonElement).disabled;
}

function setFormDisabled(disabled: boolean) {
  const elements = Array.from(getById('contact-form').querySelectorAll('input, textarea, button'));
  for (const el of elements) {
    (el as HTMLButtonElement).disabled = true;
  }
}

type FormInputElement = HTMLInputElement|HTMLTextAreaElement;

// Via https://stackoverflow.com/questions/46155/how-to-validate-an-email-address-in-javascript
const EMAIL_REGEX = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

function validateContactForm() {
  const nameEl = getFormEl('name');
  const emailEl = getFormEl('email');
  const messageEl = getFormEl('message');
  const allInputs = [nameEl, emailEl, messageEl];
  const emptyInputs = allInputs.filter(isEmptyInput);
  if (emptyInputs.length) {
    showFormError('all fields are required', emptyInputs);
    return false;
  }
  if (!EMAIL_REGEX.test(emailEl.value)) {
    showFormError('that email address looks fake', [emailEl]);
    return false;
  }
  return true;
}

function showFormError(message: string, fields: FormInputElement[] = []) {
  getById('validation-error').textContent = message;
}

function getFormEl(name: string): FormInputElement {
  return getById('contact-form').querySelector('[name="' + name + '"]') as FormInputElement;
}

function isEmptyInput(el) {
  return !el.value.trim();
}


// Adapted from https://developer.mozilla.org/en-US/docs/Web/API/Fetch_API/Using_Fetch
function postForm(formEl: HTMLFormElement, onSuccess: (this: XMLHttpRequest, ev: ProgressEvent<XMLHttpRequestEventTarget>) => void, onError: (this: XMLHttpRequest, ev: ProgressEvent<XMLHttpRequestEventTarget>) => void) {
  var xhr = new XMLHttpRequest();
  xhr.addEventListener('load', onSuccess);
  xhr.addEventListener('error', onError);
  xhr.open('POST', formEl.action);
  xhr.setRequestHeader( 'Content-Type', 'application/x-www-form-urlencoded' );
  xhr.send(urlEncodeFormInputs(formEl));
}

function urlEncodeFormInputs(formEl: HTMLElement): string {
  var formData: string[] = [];
  var inputs = formEl.querySelectorAll('input, textarea') as NodeListOf<FormInputElement>;
  for (var i = 0; i < inputs.length; i++) {
    var el = inputs[i];
    var encoded = encodeURIComponent(el.name) + '=' +
        encodeURIComponent(el.value).replace( /%20/g, '+' );
    formData.push(encoded);
  }
  return formData.join('&');
}


if (document.readyState === "complete") {
  init();
} else {
  window.addEventListener("load", init);
}

