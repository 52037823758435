import { getById } from "../util/dom";
import { Ball, BallShrinkDirection } from "./ball";
import { Vector } from "./vector";
import { Page } from "./page";
import { State } from "./state";
import { assert, checkExhaustive } from "../util/assert";
import { addScreenSizeListener, EnvironmentState, getEnvironmentState } from "./environment";
import { PageTransformer } from "./page_transformer";
import { areCirclesGoingToCollide, Collidable, Collision, findNextCollisions } from "./collision_finder";
import { areAnglesEqual, diffAngles, HALF_PI, isAngleWithin, normalizeRad, sign } from "../util/math";
import { Circle, CollisionTarget, HorizontalLine, Line, VerticalLine } from "./collidable";
import { findMin } from "../util/arrays";
import { validateBallPositions } from "./validation";
import { Bullet } from "./bullet";

const MAX_FRAME_DT = 80;
const MAX_COLLISIONS_PER_FRAME = 100;
const LOG_COLLISIONS = false;

const BOUNCINESS = 0.8;

export class Engine {
  private readonly boundRender = this.render.bind(this)

  private readonly balls: Record<Page, Ball>;
  private readonly pageTransformers: Record<Page, PageTransformer>;
  private readonly bullets: Record<Page, Bullet>;
  private currentPageTransformer: null|PageTransformer = null;

  private readonly leftScreenWall = createVerticalScreenLine(0);
  private readonly rightScreenWall = createVerticalScreenLine(getEnvironmentState().screenSize.width);
  private readonly topScreenWall =  createHorizontalScreenLine(0);
  private readonly bottomScreenWall = createHorizontalScreenLine(getEnvironmentState().screenSize.height);

  private collidables: Collidable[] = [
    this.leftScreenWall,
    this.rightScreenWall,
    this.topScreenWall,
    this.bottomScreenWall
  ];

  private state: State = { name: 'home' };
  private lastRenderTime: DOMHighResTimeStamp;
  /** This disgusting type is because I use "null" to represent home. I hope to fix this at some point. */
  private navigateToPage: Page|null|undefined = undefined;
  private handleResizeOnNextFrame = false;

  constructor() {
    addScreenSizeListener(() => {
      this.handleResizeOnNextFrame = true;
    });

    const pageTransformers = {};
    const balls = {};
    const bullets = {};
    for (const page of Object.values(Page)) {
      const bullet = new Bullet(page);
      bullets[page] = bullet;

      const pageTransformer = new PageTransformer(page, bullet);
      pageTransformers[page] = pageTransformer;

      const ball = new Ball(page);
      balls[page] = ball;
      this.collidables.push(ball);
    }
    this.bullets = bullets as Record<Page, Bullet>;
    this.pageTransformers = pageTransformers as Record<Page, PageTransformer>;
    this.balls = balls as Record<Page, Ball>;

    window['balls'] = this.balls;

    this.setInitialBallPlacement();

    this.renderBalls();
    this.lastRenderTime = performance.now();
    requestAnimationFrame(this.boundRender);
  }

  private reset() {
    this.stopMovement();
    this.setInitialBallPlacement();
  }

  private setInitialBallPlacement() {
    const {screenSize} = getEnvironmentState();
    this.contactBall.right = screenSize.width * 0.8;
    this.contactBall.top = screenSize.height * 0.25;

    this.resumeBall.left = screenSize.width * 0.2;
    this.resumeBall.centerY = screenSize.height * 0.45;

    this.tinyWebsitesBall.centerX = screenSize.width * 0.4;
    this.tinyWebsitesBall.bottom = screenSize.height * 0.8;
  }

  private stopMovement() {
    for (const ball of Object.values(this.balls)) {
      ball.dx = 0;
      ball.dy = 0;
    }
  }

  private handleResize(dt: DOMHighResTimeStamp) {
    if (!this.handleResizeOnNextFrame) return;

    this.handleResizeOnNextFrame = false;

    const {screenSize, resizeScale} = getEnvironmentState();

    this.rightScreenWall.x = screenSize.width;
    this.bottomScreenWall.y = screenSize.height;
    this.validateUnmoveables(this.rightScreenWall, this.bottomScreenWall);

    for (const transformer of Object.values(this.pageTransformers)) {
      transformer.handleResize();
    }

    if (this.currentPageTransformer) {
      this.validateUnmoveables(...this.currentPageTransformer.lines);
    }

    for (const ball of Object.values(this.balls)) {
      if (ball.disabled) continue;
      if (resizeScale.width !== 1) {
        const newRight = ball.right * resizeScale.width;
        ball.dx = (newRight - ball.right) / dt;
      }
      if (resizeScale.height !== 1) {
        const newBottom = ball.bottom * resizeScale.height;
        ball.dy = (newBottom - ball.bottom) / dt;
      }
      ball.clearDOnEndFrame = true;
    }
  }

  navigate(page: Page|null) {
    if (page === this.getCurrentPage()) return;
    this.navigateToPage = page;
  }

  getCurrentPage(): Page|null {
    const {state} = this;
    if (state.name === 'home') return null;
    if (state.name === 'switching-page') return state.to;
    return state.page;
  }

  applyNavigationRequest(page: Page|null) {
    const currentState = this.state.name;
    switch (currentState) {
      case 'navigating-page':
        this.balls[this.state.page].cancelDrive();
        this.bullets[this.state.page].removeCurrent();

        if (!page) {
          this.state = {name: 'home'};
        } else {
          this.initNavigatingPage(page);
        }
        return;
      case 'home':
        if (!page) return;
        this.initNavigatingPage(page);
        return;
      case 'page-idle': 
      case 'expanding-page':
      case 'closing-page':
        if (!page) {
          this.initClosingPage(this.state.page);
          return;
        } else if (page === this.state.page) {
          this.initExpandingPage(this.state.page);
        } else {
          this.initSwitchingPage(this.state.page, page)
        }
        break;
      case 'switching-page':
        if (page === this.state.to) return;

        this.balls[this.state.to].cancelDrive();
        this.bullets[this.state.to].removeCurrent();

        if (page) {
          this.initSwitchingPage(this.state.from, page)
        } else {
          this.initClosingPage(this.state.from);
        }
        return;
      default:
         checkExhaustive(currentState);
    }
  }

  maybeChangeState() {
    const {state} = this;
    const name = state.name
    switch (name) {
      case 'home':
        return;
      case 'navigating-page':
        {
          const ball = this.balls[state.page];
          if (ball.drivingComplete) {
            this.initExpandingPage(state.page);
          }
          return;
        }
      case 'expanding-page':
        if (this.currentPageTransformer!.isComplete) {
          this.initPageIdle(state.page);
        }
        return;
      case 'page-idle': 
        return;
      case 'closing-page':
        if (this.currentPageTransformer!.isComplete) {
          this.currentPageTransformer = null;
          const ball = this.balls[state.page];
          ball.setDisabled(false);
          this.bullets[state.page].removeCurrent();
          this.state = {name: 'home'};
        }
        return;
      case 'switching-page':
        {
          const ball = this.balls[state.to];
          const drivingComplete = ball.drivingComplete;
          const transformer = this.currentPageTransformer!;
          const closingComplete = transformer.isComplete
          if (drivingComplete && closingComplete) {
            const fromBall = this.balls[state.from];
            fromBall.setDisabled(false);
            this.initExpandingPage(state.to);
          } else if (closingComplete) {
            this.currentPageTransformer = null;
            const fromBall = this.balls[state.from];
            fromBall.setDisabled(false);
            this.bullets[state.from].removeCurrent();
            this.state = {name: 'navigating-page', page: state.to};
          }
          return;
        }
      default:
         checkExhaustive(name);
    }
  }

  private initExpandingPage(page: Page) {
    this.state = { name: 'expanding-page', page };
    const ball = this.balls[page];
    ball.setDisabled(true);
    const transformer = this.pageTransformers[page];
    this.currentPageTransformer = transformer;
    transformer.startOpen();
    const openDuration = transformer.duration;
    for (const ball of Object.values(this.balls)) {
      if (ball.name !== page) {
        ball.startShrinking(BallShrinkDirection.SHRINKING, openDuration);
      }
    }
  }

  private initPageIdle(page: Page) {
    this.state = { name: 'page-idle', page };
  }

  private initClosingPage(page: Page) {
    this.state = { name: 'closing-page', page };
    const transformer = this.currentPageTransformer!;
    transformer.startClose();
    const closeDuration = transformer.duration;
    for (const ball of Object.values(this.balls)) {
      if (ball.name !== page) {
        ball.startShrinking(BallShrinkDirection.GROWING, closeDuration);
      }
    }
  }

  private initSwitchingPage(from: Page, to: Page) {
    this.initClosingPage(from);
    this.initNavigatingPage(to);
    this.state = {name: 'switching-page', from, to};
  }

  private initNavigatingPage(page: Page) {
    this.bullets[page].setCurrent();
    const ball = this.balls[page];
    this.state = {name: 'navigating-page', page};
    ball.driveToCenter();
  }
  
  private tickPhysics(frameDt: DOMHighResTimeStamp) {
    let collisionsPerFrame = 0;

    // This is just used for logging.
    const initialState = {};
    for (const ball of Object.values(this.balls)) {
      initialState[ball.name] = ball.serialize();
    }

    const collidables = this.getCollidables();
    
    let remainingDt: DOMHighResTimeStamp = frameDt;
    while (remainingDt > 0) {
      let collision: Collision|undefined = undefined;
      // The MAX_COLLISIONS_PER_FRAME check is purely to deal with garbage bugs from crashing the system.
      if (collisionsPerFrame < MAX_COLLISIONS_PER_FRAME) {
        const collisions = findNextCollisions(collidables, remainingDt);
        collision = findMin(collisions, c => c.dt);
      }
      if (collision) {
        this.maybeLogCollision(collisionsPerFrame, initialState, collision, remainingDt);

        // We don't want to be at the point of collision, we want to be just before it.
        collision.dt -= 0.00001;
        if (collision.dt < 0) collision.dt = 0;

        // Move everything up to the time of the collision.
        this.moveAll(collision.dt, remainingDt - collision.dt);
        this.applyCollision(collision, collisionsPerFrame);
        
        collisionsPerFrame++;
        
        remainingDt -= collision.dt;
      } else {
        // No more collisions, let things move.
        this.moveAll(remainingDt, 0);
        remainingDt = 0;
      }
    }

    if (collisionsPerFrame >= 30) {
      console.log({collisionsPerFrame});
    }
  }

  private moveAll(dt: DOMHighResTimeStamp, frameTimeRemaining: DOMHighResTimeStamp) {
    for (const ball of Object.values(this.balls)) {
      ball.move(dt, frameTimeRemaining);
    }
    this.currentPageTransformer?.move(dt, frameTimeRemaining);
  }

  private getCollidables(): Collidable[] {
    const collidables = this.collidables.filter(obj => !(obj instanceof Ball) || !obj.disabled);
    if (this.currentPageTransformer) {
      collidables.push(this.currentPageTransformer);
    }
    return collidables;
  }
  
  applyCollision(collision: Collision, collisionsPerFrame: number) {
    // Reduce the energy in the system every time there's a collision in
    // the frame, to prevent the need for an infinite number of collisions per frame.
    const decay = 1 - collisionsPerFrame * 0.05;

    // Capture the original dx and dy.
    const loc = collision.loc;

    let obj1: Ball = collision.ball;
    let obj2: CollisionTarget = collision.target;
    // If only one of the two objects is moveable, make sure it's obj1.
    // This just simplifies code later.
    if (!obj1.moveable && obj2 instanceof Ball) {
      const tmp = obj1;
      obj1 = obj2;
      obj2 = tmp;
    }

    // Update dx and dy.
    // Rotate the objects so that their dx and dy line up with the direction of the collision.
    // Then, simply transfer the y values between the two (if both moving), but leave x in tact.
    // Finally, undo the rotation.
    // I thought of this magic trick on a run.

    const obj1D = new Vector(obj1.dx, obj1.dy);
    const obj2D = obj2.type === 'circle' ? new Vector(obj2.dx, obj2.dy) : new Vector(0,0);

    const angleOfCollision = Math.atan2(loc.x - obj1.centerX, loc.y - obj1.centerY);

    let obj1IsMoveable = obj1.moveable;
    const obj2IsMoveable = obj2 instanceof Ball && obj2.moveable;


    if (obj1 && isInUnmoveableRange(obj1, angleOfCollision)) {
        obj1IsMoveable = false;
    }

    if (!obj1IsMoveable && !obj2IsMoveable) {
      obj1D.angle += angleOfCollision;
      obj2D.angle += angleOfCollision;

      obj1D.y = 0;
      obj2D.y = 0;

      obj1D.angle -= angleOfCollision;
      obj2D.angle -= angleOfCollision;
    } else if (obj2 instanceof Ball &&  obj1.unmoveableX &&
                sign(obj2.dx) === sign(obj1.unmoveableX)) {
      // const goingUp = angleOfCollision > -HALF_PI && angleOfCollision < HALF_PI;
      // angleOfCollision = goingUp ? 0 : Math.PI;
      obj1D.y = obj2D.velocity * Math.sign(obj2D.y) * 0.99;
      obj1D.x = 0;
      obj2D.x = 0;

      // TODO - we need to let obj2D slide in the Y direction some amount so that it
      // slips along the ball. Some kind of ratio between angleOfCollision and obj2D.x
    } else if (obj2 instanceof Ball && obj1.unmoveableY &&
               sign(obj2.dy) === sign(obj1.unmoveableY)) {
      // const goingLeft = angleOfCollision > 0 && angleOfCollision < Math.PI;
      // angleOfCollision = goingLeft ? -HALF_PI : HALF_PI; 
      obj1D.x = obj2D.velocity * Math.sign(obj2D.x) * 0.99;
      obj1D.y = 0;
      obj2D.y = 0;

      // TODO - we need to let obj2D slide in the Y direction some amount so that it
      // slips along the ball. Some kind of ratio between angleOfCollision and obj2D.x
    } else {
      // Apply normal bounce logic.
      obj1D.angle += angleOfCollision;
      obj2D.angle += angleOfCollision;

      obj1D.y *= BOUNCINESS;
      obj2D.y *= BOUNCINESS;

      obj1D.y += obj1.dradius * getRadiusMultiplier(obj1);
      if (obj2.type === 'circle') {
        obj2D.y -= obj2.dradius * getRadiusMultiplier(obj2);
      }

      if (obj1IsMoveable && obj2IsMoveable) {
        const obj2dy = obj2D.y;
        obj2D.y = obj1D.y;
        obj1D.y = obj2dy;
      } else {
        // obj1 is moveable, and obj2 is !moveable.

        // If it's a ball, its' a grabbed ball.
        let multiplier = (obj2 instanceof Ball ? 1 / BOUNCINESS : 1);
        if (sign(obj1D.y) === sign(obj2D.y)) {
          // They are moving in the same direction.
          if (Math.abs(obj1D.y) < Math.abs(obj2D.y)) {
            // Treat he obj2 ball like it weighs more.
            obj1D.y = obj2D.y * multiplier;
          } else {
            // We caught up to the thing being grabbed. Bounce off of it.
            obj1D.y = (obj1D.y - obj2D.y) * -1;
          }
        } else {
          obj1D.y = obj1D.y * -1 + obj2D.y * multiplier;
        }
      }


      // Apply the balls own dradius to the velocity.
      if (obj1.dradius > 0) {
        obj1D.y -= obj1.dradius * 1.3;
      }
      if (obj2.type === 'circle' && obj2.dradius > 0) {
        obj2D.y += obj2.dradius * 1.3;
      }

      obj1D.angle -= angleOfCollision;
      obj2D.angle -= angleOfCollision;
    }

    obj1D.velocity *= decay;
    obj2D.velocity *= decay;

    obj1.dx = obj1D.x;
    obj1.dy = obj1D.y;
    if (obj2 instanceof Ball) {
      obj2.dx = obj2D.x;
      obj2.dy = obj2D.y;
    }
  
    const LOCK_AMOUNT = 0.1;
    // TODO - this should also account for unmoveable circles
    if (obj2.type === 'vertical-line') {
      const isToRight = areAnglesEqual(angleOfCollision, Math.PI * 0.5);
      obj1.unmoveableX = isToRight ? LOCK_AMOUNT : -LOCK_AMOUNT;
    } else if (obj2.type === 'horizontal-line') {
      const isToBottom = areAnglesEqual(angleOfCollision, 0);
      obj1.unmoveableY= isToBottom ? LOCK_AMOUNT : -LOCK_AMOUNT;
    }
  }

  private render() {
    const time = performance.now();
    let frameDt = time - this.lastRenderTime;
    if (frameDt > MAX_FRAME_DT) {
      /// The frame time can get super high due to the debugger pause or being on another
      // tab for a while. To prevent total chaos, cap the amount of frame time we accept.
      frameDt = MAX_FRAME_DT;
    }
    this.lastRenderTime = time;

    this.handleResize(frameDt);

    if (this.navigateToPage !== undefined) {
      this.applyNavigationRequest(this.navigateToPage);
      this.navigateToPage = undefined;
    }

    for (const ball of Object.values(this.balls)) {
      ball.initFrame(frameDt);
    }
    this.currentPageTransformer?.initFrame(frameDt);

    this.tickPhysics(frameDt);

    this.renderBalls();
    if (this.isTransformingState()) {
      this.currentPageTransformer?.render();
    }
    
    for (const ball of Object.values(this.balls)) {
      ball.endFrame(frameDt);
    }

    validateBallPositions(Array.from(Object.values(this.balls)), this.currentPageTransformer);
    if (this.currentPageTransformer) {
      this.validateUnmoveables(...this.currentPageTransformer.lines);
    }
    this.maybeChangeState();
    requestAnimationFrame(this.boundRender);
  }

  private renderBalls() {
    for (const ball of Object.values(this.balls)) {
      ball.render();
    }
  }

  private validateUnmoveables(...lines: Line[]) {
    const UNLOCK_DISTANCE = 1;
    const hLines = lines.filter(l => l.type === 'horizontal-line');
    const vLines = lines.filter(l => l.type === 'vertical-line');
    for (const ball of Object.values(this.balls)) {
      if (ball.unmoveableY) {
        const found = hLines.find(line =>
          (ball.x >= line.left && ball.x <= line.right) &&
          (Math.abs(ball.top - line.y) < UNLOCK_DISTANCE || Math.abs(ball.bottom - line.y) < UNLOCK_DISTANCE));
        if (!found) {
          ball.unmoveableY = 0;
        }
      }
      if (ball.unmoveableX) {
        const found = vLines.find(line =>
          (ball.y >= line.top && ball.y <= line.bottom) &&
          (Math.abs(ball.left - line.x) < UNLOCK_DISTANCE || Math.abs(ball.right - line.x) < UNLOCK_DISTANCE));
        if (!found) {
          ball.unmoveableX = 0;
        }
      }
    }
  }

  private isTransformingState() {
    const name = this.state.name;
    return name === 'expanding-page' || name === 'closing-page' || name === 'switching-page';
  }

  /** Red */
  get contactBall(): Ball {
    return this.balls[Page.CONTACT];
  }

  /** Yellow */
  get resumeBall(): Ball {
    return this.balls[Page.RESUME];
  }

  /** Green */
  get tinyWebsitesBall(): Ball {
    return this.balls[Page.TINY_WEBSITES];
  }

  private maybeLogCollision(collisionsPerFrame: number, initialState: any, collision: Collision, remainingDt: number) {
    if (!LOG_COLLISIONS) return;
    if (collisionsPerFrame === 0) {
      console.log('\n\n\nInit frame collision');
      console.log(initialState);
    }
    console.log('Found collision', collision);
    console.log('collision.ball',  collision.ball.serialize());
    console.log('collision.target', ((collision.target instanceof Ball) ? collision.target.serialize() : collision.target));
    console.log('Remaining dt ', remainingDt);
    getById('collision-point').style.transform = `translate3d(${collision.loc.x}px, ${collision.loc.y}px, 0)`;
  }
}

function isInUnmoveableRange(ball: Ball, angle: number): boolean {
  if (!ball.unmoveableX && !ball.unmoveableY) return false;

  let xAngle = ball.unmoveableX > 0 ? Math.PI * -0.5 : Math.PI * 0.5;
  let yAngle = ball.unmoveableY > 0 ? Math.PI : 0;

  if (!ball.unmoveableY) {
    return areAnglesEqual(yAngle, angle);  
  }
  if (!ball.unmoveableX) {
    return areAnglesEqual(xAngle, angle);  
  }
  return isAngleWithin(angle, xAngle, yAngle);
}

function createVerticalScreenLine(x: number): VerticalLine {
  return {
    type: 'vertical-line',
    x,
    top: Number.NEGATIVE_INFINITY,
    dtop: 0,
    bottom: Number.POSITIVE_INFINITY,
    dbottom: 0,
  }
}

function createHorizontalScreenLine(y: number): HorizontalLine {
  return {
    type: 'horizontal-line',
    y,
    left: Number.NEGATIVE_INFINITY,
    dleft: 0,
    right: Number.POSITIVE_INFINITY,
    dright: 0,
  }
}

function getRadiusMultiplier(obj: Circle): number {
  if (obj.dradius <= 0) return 1;
  if (obj instanceof Ball) return 1.3;
  return 1.2;
}