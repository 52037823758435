export function findMin<T>(arr: Array<T|undefined>, getter: (T) => number = (val) => val): T|undefined {
  const definedArr = arr.filter(v => v !== undefined) as T[];
  if (definedArr.length === 0) {
    return undefined; // Handle empty array case
  }
  let minItem = definedArr[0]; 
  let minValue = getter(definedArr[0]);

  for (let i = 1; i < definedArr.length; i++) {
    const currentValue = getter(definedArr[i]);
    if (currentValue < minValue) {
      minValue = currentValue;
      minItem = definedArr[i];
    }
  }

  return minItem;
}

export function forEachPair<T>(arr: T[], fn: (val1: T, val2: T) => T|undefined|void): T|undefined {
  for (let i = 0; i < arr.length; i++) {
    const val1 = arr[i];
    for (let j = i + 1; j < arr.length; j++) {
      const result = fn(val1, arr[j]);
      if (result !== undefined) return result;
    }
  }
  return undefined;
}