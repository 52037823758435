import { randomFloat } from "../util/math";
import { getById, setCssProperty } from "../util/dom";
import { getEnvironmentState } from "./environment";

interface ParticleConfig {
  x: number;
  y: number;
  dx: number;
  dy: number;
}

export class ParticlePool {
  private nextParticle: number;
  private readonly particles: ThrustParticle[];

  constructor(size: number, creator: () => ThrustParticle) {
    this.particles = new Array(size);
    for (let i = 0; i < size; i++) {
      this.particles[i] = creator();
    }
    this.nextParticle = 0;
  }

  spawn(count: number, config: ParticleConfig) {
    for (let i = 0; i < count; i++) {
      this.spawnOne(config);
    }
  }

  spawnOne(config: ParticleConfig) {
    this.particles[this.nextParticle].show(config);
    this.nextParticle++;
    if (this.nextParticle === this.particles.length) {
      this.nextParticle = 0;
    }

  }

  move(dt: DOMHighResTimeStamp) {
    for (const particle of this.particles) {
      particle.move(dt);
    }
  }

  render() {
    for (const particle of this.particles) {
      particle.render();
    }
  }
}

export class ThrustParticle {
  protected el: HTMLElement;

  protected x = 0;
  protected y = 0;
  protected xWithWave = 0;
  protected yWithWave = 0;
  protected dx = 0;
  protected dy = 0;
  protected hidden = true;
  protected opacity = 0;
  protected shownTime = 0;
  protected waveOffest = 0;
  protected waveStrength = 0;
  protected waveSpeed = 0;

  protected readonly initialOpacity = 0.8;
  protected readonly opacityDecayRate = 0.0005 + Math.random() * 0.0005;

  constructor(sourceHue: number) {
    this.el = document.createElement('div');
    this.el.classList.add('particle');
    getById('particles').appendChild(this.el);

    const radius = Math.ceil(Math.random() * 4) * 0.5 + 0.5;
    setCssProperty(this.el, '--particle-radius', `${radius}px`);

    const hue = sourceHue + Math.floor(Math.random() - 0.5) * 25;
    const lightness = Math.floor(randomFloat(65, 90));
    this.el.style.background = `hsl(${hue}deg, 100%, ${lightness}%)`;

    this.hide();
  }

  hide() {
    this.el.style.transform = 'translate3d(-500px, -500px, 0)';
    this.el.style.opacity = String(this.initialOpacity);
    this.hidden = true;
  }

  show(config: ParticleConfig) {
    const screenMultiplier = getEnvironmentState().screenSize.diagnol * 0.001;
    
    this.hidden = false;
    this.x = config.x;
    this.y = config.y;

    this.dx = config.dx;
    this.dy = config.dy;
    this.dx *= (Math.random() - 0.5) * 0.1 + 0.9;
    this.dy *= (Math.random() - 0.5) * 0.1 + 0.9;

    this.opacity = this.initialOpacity;

    this.waveStrength = Math.random() * 20 * screenMultiplier + 5 * screenMultiplier;
    this.waveSpeed = Math.random() * 0.004 * screenMultiplier + 0.0005 * screenMultiplier;
    this.waveOffest = Math.random() * Math.PI;

    this.shownTime = 0;
    
    this.move(0);
  }

  move(dt: DOMHighResTimeStamp) {
    if (this.hidden) return;

    this.shownTime += dt;

    this.x += this.dx * dt;
    this.y += this.dy * dt;

    this.applyWave();

    this.opacity -= dt * this.opacityDecayRate;
    if (this.opacity < 0.05) {
      this.hide();
    }

    if (!this.hidden) {
      this.render();
    }
  }

  private applyWave() {
    const angleOfMovement = Math.atan2(this.dy, this.dx);
    const perpindicular = angleOfMovement + Math.PI / 2;

    const waveRadians = this.waveSpeed * (this.shownTime + this.waveOffest);
    const amount = Math.sin(waveRadians) * this.waveStrength;

    this.xWithWave = this.x + Math.cos(perpindicular) * amount;
    this.yWithWave = this.y + Math.sin(perpindicular) * amount;
  }

  render() {
    if (this.hidden) return;
    this.el.style.transform = `translate3d(${this.xWithWave}px, ${this.yWithWave}px, 0)`;
    this.el.style.opacity = String(this.opacity);
  }
}