import { checkExhaustive } from "../util/assert";
import { Ball } from "./ball";
import { getEnvironmentState } from "./environment";
import { PageTransformer } from "./page_transformer";
import { Rectangle } from "../util/types";
import { forEachPair } from "../util/arrays";
import { randomFloat, sq } from "../util/math";
import { Circle } from "./collidable";

const FIX_BUFFER = 1;

/**
 * Makes sure none of the balls are out of bounds.
 */
export function validateBallPositions(balls: Ball[], pageTransformer: PageTransformer|null) {
  const {screenSize} = getEnvironmentState();
  balls = balls.filter(b => !b.disabled);
  // TODO - handle running page transformers.
  if (pageTransformer && !pageTransformer.isFullyOpen) {
    pageTransformer = null;
  }

  // First make sure it's not off screen.
  for (const ball of balls) {
    moveInsideScreen(ball);

    // Now make sure we're not inside of a PageTransformer
    if (pageTransformer) {
      moveOutsidePageTransformer(ball, pageTransformer);
    }
  }

  // Now make sure no balls are overlapping.
  // Bounds describes where to put the ball randomly if it's collided.
  const bounds: Rectangle = {
    top: 0,
    // Avoid overlapping with at least some of the navigation.
    left: 120,
    right: screenSize.width,
    // If there's a PageTranformer open, just stick it above since there should be plenty
    // of room up there.
    bottom: pageTransformer ? pageTransformer.rectangle.top : screenSize.height,
  };
  let overlapping = findOverlappingBall(balls);
  while (overlapping) {
    const diameter = overlapping.radius * 2;
    setLeft(overlapping, 
        randomFloat(bounds.left, bounds.right - diameter));
    setTop(overlapping,
        randomFloat(bounds.top, bounds.bottom - diameter));
    overlapping.dx = 0;
    overlapping.dy = 0;
     
    overlapping = findOverlappingBall(balls);
  }
}

function findOverlappingBall(balls: Ball[]): Ball|undefined {
  return forEachPair(balls, (ball1, ball2) => {
    if (ball1.isGrabbed && ball2.isGrabbed) return;
    if (areCirclesColliding(ball1, ball2)) {
      return ball1.isGrabbed ? ball2 : ball1;
    }
  });
}

function moveInsideScreen(ball: Ball) {
  const {screenSize} = getEnvironmentState();
  if (ball.left < 0) {
    setLeft(ball, 0);
  } else if (ball.right > screenSize.width) {
    setRight(ball, screenSize.width);
  }
  if (ball.top < 0) {
    setTop(ball, 0);
  } else if (ball.bottom > screenSize.height) {
    setBottom(ball, screenSize.height);
  }
}

function moveOutsidePageTransformer(ball: Ball, pageTransformer: PageTransformer) {
  const rect = pageTransformer.rectangle;
  const radius = rect.borderRadius ?? 0;
  let isInside = false;
  if (ball.x >= rect.left + radius && ball.x <= rect.right - radius &&
      ball.bottom > rect.top && ball.top < rect.bottom) {
    // Inside a horizontal wall.
    isInside = true;
  } else if (ball.y >= rect.top + radius && ball.y <= rect.bottom - radius &&
      ball.right > rect.left && ball.left < rect.right) {
    // Inside a vertical wall.
    isInside = true;
  }
  
  if (isInside) {
    // It's inside a wall, move it to the closest wall.
    let closestWall: 'left'|'right'|'top'|'bottom' = 'left';
    let closestDistance = Number.POSITIVE_INFINITY;

    const topDist = Math.abs(ball.y - rect.top);
    if (topDist < closestDistance) {
      closestWall = 'top';
      closestDistance = topDist;
    }
    const bottomDist = Math.abs(ball.y - rect.bottom);
    if (bottomDist < closestDistance) {
      closestWall = 'bottom';
      closestDistance = bottomDist;
    }
    const leftDist = Math.abs(ball.x - rect.left);
    if (leftDist < closestDistance) {
      closestWall = 'left';
      closestDistance = leftDist;
    }
    const rightDist = Math.abs(ball.x - rect.right);
    if (rightDist < closestDistance) {
      closestWall = 'right';
      closestDistance = rightDist;
    }

    switch (closestWall) {
      case 'top':
        setBottom(ball, rect.top);
        break;
      case 'bottom':
        setTop(ball, rect.bottom);
          break;
      case 'left':
        setRight(ball, rect.left);
        break;
      case 'right':
        setLeft(ball, rect.right);
        break;
      default: checkExhaustive(closestWall);
    }
    return;
  }

  // Now check the corners.
  const corners = pageTransformer.cornerCircles;
  if (areCirclesColliding(ball, corners[0])) {
    const adjustment = getBallSquareAdjustment(ball, radius);
    setBottom(ball, rect.top + adjustment);
    setRight(ball, rect.left + adjustment);
    return;
  }
  if (areCirclesColliding(ball, corners[1])) {
    const adjustment = getBallSquareAdjustment(ball, radius);
    setBottom(ball, rect.top + adjustment);
    setLeft(ball, rect.right - adjustment);
    return;
  }
  if (areCirclesColliding(ball, corners[2])) {
    const adjustment = getBallSquareAdjustment(ball, radius);
    setTop(ball, rect.bottom - adjustment);
    setRight(ball, rect.left + adjustment);
    return;
  }
  if (areCirclesColliding(ball, corners[3])) {
    const adjustment = getBallSquareAdjustment(ball, radius);
    setTop(ball, rect.bottom - adjustment);
    setLeft(ball, rect.right - adjustment);
    return;
  }
}

const COS_OF_45 = Math.cos(Math.PI * 0.25);

/**
 * The distance you need to subtract such that the rectangle and the 
 * ball are touching corners.
 * No idea what you would call this.
 */
function getBallSquareAdjustment(ball: Ball, cornerRadius: number): number {
  const ballDist = ball.radius - ball.radius * COS_OF_45;
  const rectDist = cornerRadius - cornerRadius * COS_OF_45;
  return ballDist + rectDist - FIX_BUFFER;
}

function setLeft(ball: Ball, pos: number) {
  debugger;
  ball.left = pos + FIX_BUFFER;
  ball.unmoveableX = 0;
}

function setRight(ball: Ball, pos: number) {
  debugger;
  ball.right = pos - FIX_BUFFER;
  ball.unmoveableX = 0;
}

function setTop(ball: Ball, pos: number) {
  debugger;
  ball.top = pos + FIX_BUFFER;
  ball.unmoveableY = 0;
}

function setBottom(ball: Ball, pos: number) {
  debugger;
  ball.bottom = pos - FIX_BUFFER;
  ball.unmoveableY = 0;
}

function areCirclesColliding(obj1: Circle, obj2: Circle): boolean {
  const xDiff = obj1.x - obj2.x;
  const yDiff = obj1.y - obj2.y;
  const distance = (xDiff * xDiff + yDiff * yDiff);
  const touching = sq((obj1.radius)) + sq((obj2.radius));
  return distance < touching;
}