export enum Page {
  CONTACT = 'contact',
  RESUME = 'resume',
  TINY_WEBSITES = 'tiny-websites',
}

export interface PageConfig {
  name: Page;
  // The radius of the ball will be radiusScale * getMaxBallRadius
  radiusScale: number;
  // The color of the ball.
  hue: number;
}

export const PAGE_CONFIGS: { [key in Page]: PageConfig } = {
  [Page.CONTACT]: {
    name: Page.CONTACT,
    radiusScale: 1,
    hue: 349,
  },
  [Page.RESUME]: {
    name: Page.RESUME,
    radiusScale: 0.75,
    hue: 43,
  },
  [Page.TINY_WEBSITES]: {
    name: Page.TINY_WEBSITES,
    radiusScale: 0.9,
    hue: 154,
  },
};