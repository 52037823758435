import { createRectangle, Location, Rectangle } from "../util/types";
import { Page, PAGE_CONFIGS } from "./page";
import { addScreenSizeListener, EnvironmentState, getBallRadius, getEnvironmentState } from './environment';
import { Circle, Line } from "./collidable";
import { clamp, valueAlongScale } from "../util/math";
import { Bullet } from "./bullet";
import { easeInOutQuad } from "../util/animation";

enum Direction {
  OPEN,
  CLOSE,
}


// When closing, we want to turn the page back into a ball as soon
// as possible.
const CLOSING_DURATION_PERCENT_CUTOFF = 0.95;

export class PageTransformer {
  readonly moveable = false;

  readonly config = PAGE_CONFIGS[this.page];
  readonly ballEl: HTMLElement;
  readonly wrapperEl: HTMLElement;
  readonly contentEl: HTMLElement;

  private timePassed: DOMHighResTimeStamp = Number.POSITIVE_INFINITY;
  duration: DOMHighResTimeStamp = 0;
  private direction = Direction.CLOSE;
  private expansionPercent = 0;

  private contentWidth: number = 0;
  private contentHeight: number = 0;
  private wrapperDimension: number = 0;

  lines: Line[] = [];
  rectangle: Rectangle;

  dradius: number;

  constructor(readonly page: Page, readonly bullet: Bullet) {
    const ballEl = document.querySelector(`.ball[data-page="${page}"]`);
    if (!ballEl) throw 'Could not find ballEl';
    this.ballEl = ballEl as HTMLElement;
    this.wrapperEl = this.ballEl.querySelector('.wrapper') as HTMLElement;
    this.contentEl = this.ballEl.querySelector('.content') as HTMLElement;

    this.applyMeasurements();
    this.setExpansionPercentage(0);
  }

  handleResize() {
    this.applyMeasurements();
  }

  private applyMeasurements() {
    this.contentWidth = this.contentEl.offsetWidth;
    this.setCssProperty('--content-width-px', this.contentWidth + 'px');
    this.contentHeight = this.contentEl.offsetHeight;
    this.setCssProperty('--content-height-px', this.contentHeight + 'px');

    const contentDiagonal = Math.sqrt(this.contentWidth * this.contentWidth + this.contentHeight * this.contentHeight);
    this.wrapperDimension = contentDiagonal;
    this.setCssProperty('--wrapper-dimension', this.wrapperDimension);
    this.setCssProperty('--wrapper-dimension-px', this.wrapperDimension + 'px');

    // TODO - this should get updated after a transition is complete.
    this.duration = this.calculateDuration();

    this.rectangle = createRectangle({ width: this.contentWidth, height: this.contentHeight, center: this.center });
  }

  initFrame(dt: DOMHighResTimeStamp) {
    this.dradius = this.calculateDRadius(dt);
  }

  move(dt: DOMHighResTimeStamp, frameTimeRemaining: DOMHighResTimeStamp) {
    this.expansionPercent = this.calculateExpansionPercentage(dt);
    this.timePassed += dt;
    if (this.isComplete) {
      this.dradius = 0;
    } else {
      this.dradius = this.calculateDRadius(frameTimeRemaining);
    }
    this.updateLines();
  }

  get center(): Location {
    return getEnvironmentState().screenCenter;
  }

  private updateLines() {
    const radius = this.radius;
    const diameter = radius * 2;

    const lines: Line[] = [];
    if (diameter > this.contentWidth) {
      const width = this.contentWidth;
      const halfWidth = width * 0.5;
      const height = Math.min(this.contentHeight, 2 * Math.sqrt(radius * radius - halfWidth * halfWidth));
      const halfHeight = height * 0.5;
      const center = this.center;
      const top = center.y - halfHeight;
      const dtop = -this.dradius;
      const bottom = center.y + halfHeight;
      const dbottom = this.dradius;
      lines.push(
        // left line
        {
          type: 'vertical-line',
          x: center.x - halfWidth,
          top, dtop, bottom, dbottom,
        },
        // right line
        {
          type: 'vertical-line',
          x: center.x + halfWidth,
          top, dtop, bottom, dbottom,
        }
      );
    }
    if (diameter > this.contentHeight) {
      const height = this.contentHeight;
      const halfHeight = height * 0.5;
      const width = Math.min(this.contentWidth, 2 * Math.sqrt(radius * radius - halfHeight * halfHeight));
      const halfWidth = width * 0.5;
      const center = this.center;
      const left = center.x - halfWidth;
      const dleft = -this.dradius;
      const right = center.x + halfWidth;
      const dright = this.dradius;
      lines.push(
        // left line
        {
          type: 'horizontal-line',
          y: center.y - halfHeight,
          left, dleft, right, dright,
        },
        // right line
        {
          type: 'horizontal-line',
          y: center.y + halfHeight,
          left, dleft, right, dright,
        }
      );
    }
    this.lines = lines;
  }

  get circle(): Circle {
    return {
      type: 'circle',
      x: this.center.x,
      y: this.center.y,
      dx: 0,
      dy: 0,
      radius: this.radius,
      dradius: this.dradius,
    }
  }

  private calculateDRadius(dt: DOMHighResTimeStamp) {
    if (this.isComplete) return 0;
    if (dt <= 0) dt = 0.00000001;
    const nextRadius = this.calculateNextRadius(dt);
    return (nextRadius - this.radius) / dt;
  }
  
  get radius(): number {
    return this.calculateRadiusAt(this.expansionPercent);
  }

  private calculateNextRadius(dt: DOMHighResTimeStamp): number {
    return this.calculateRadiusAt(this.calculateExpansionPercentage(dt));
  }

  calculateRadiusAt(expansionPercent: number): number {
    const start = getBallRadius(this.config).big;
    const end = this.wrapperDimension * 0.5;
    return (end - start) * expansionPercent + start;
  }

  startOpen() {
    this.startTransition(Direction.OPEN);
    this.bullet.startTransform(this.duration);
    this.ballEl.classList.add('current');
  }

  startClose() {
    this.startTransition(Direction.CLOSE);
    this.bullet.reverseTransform(this.duration);
    this.ballEl.classList.remove('current');
  }

  private startTransition(direction: Direction) {
    if (this.isComplete) {
      this.timePassed = 0;
    } else {
      if (this.direction === direction) {
        return;
      }
      this.timePassed = this.duration - this.timePassed;
    }
    this.direction = direction;
  }

  render() {
    this.setExpansionPercentage(this.expansionPercent);
  }

  get isExpanding(): boolean {
    return this.direction === Direction.OPEN && !this.isComplete;
  }

  get isFullyOpen(): boolean {
    return this.direction === Direction.OPEN && this.isComplete;
  }

  get isComplete(): boolean {
    if (this.direction === Direction.OPEN) {
      return this.timePassed >= this.duration;
    } else {
      return this.timePassed >= this.duration * CLOSING_DURATION_PERCENT_CUTOFF;
    }
  }

  get isActive(): boolean {
    return !this.isComplete || this.direction === Direction.OPEN;
  }

  private setCssProperty(name: string, value: string|number) {
    this.ballEl.style.setProperty(name, value.toString());
  }

  private calculateExpansionPercentage(dt: DOMHighResTimeStamp): number {
    const timePassed = this.timePassed + dt;
    let percent = this.duration ? timePassed / this.duration : 1;
    percent = clamp(percent, 0, 1);
    if (this.direction === Direction.CLOSE) {
      percent = 1 - percent;
    }
    percent = easeInOutQuad(percent);
    if (this.direction === Direction.CLOSE && timePassed >= this.duration * CLOSING_DURATION_PERCENT_CUTOFF) {
      percent = 0;
    }
    return percent;
  }

  setExpansionPercentage(percent: number) {
    this.setCssProperty('--transition-percent', percent);
  }

  private calculateDuration(): number {
    const ballDiameter = getBallRadius(this.config).big * 2;
    const percent = clamp((this.wrapperDimension - ballDiameter) / 800, 0, 1);
    return valueAlongScale(330, 670, percent);
  }
}