import { randomFloat } from "../util/math";
import { getById, setCssProperty } from "../util/dom";
import { getEnvironmentState } from "./environment";

interface ParticleConfig {
  x: number;
  y: number;
  dx: number;
  dy: number;
}

export class ParticlePool {
  private nextParticle: number;
  private readonly particles: ThrustParticle[];

  constructor(size: number, creator: () => ThrustParticle) {
    this.particles = new Array(size);
    for (let i = 0; i < size; i++) {
      this.particles[i] = creator();
    }
    this.nextParticle = 0;
  }

  spawn(count: number, config: ParticleConfig) {
    for (let i = 0; i < count; i++) {
      this.spawnOne(config);
    }
  }

  spawnOne(config: ParticleConfig) {
    this.particles[this.nextParticle].show(config);
    this.nextParticle++;
    if (this.nextParticle === this.particles.length) {
      this.nextParticle = 0;
    }

  }
}

export class ThrustParticle {
  private el: HTMLElement;
  private animation?: Animation;
  private readonly duration = Math.floor(350 + Math.random() * 150);

  constructor(sourceHue: number) {
    this.el = document.createElement('div');
    this.el.classList.add('particle');
    getById('particles').appendChild(this.el);

    const radius = Math.ceil(Math.random() * 3) * 0.5 + 0.5;
    setCssProperty(this.el, '--particle-radius', `${radius}px`);

    const hue = sourceHue + Math.floor(Math.random() - 0.5) * 25;
    const lightness = Math.floor(randomFloat(65, 90));
    this.el.style.background = `hsl(${hue}deg, 100%, ${lightness}%)`;

    this.hide();
  }

  hide() {
    this.el.style.transform = 'translate3d(-500px, -500px, 0)';
  }

  show(config: ParticleConfig) {
    let {x, y, dx, dy} = config;

    this.animation?.cancel();

    dx *= (Math.random() - 0.5) * 0.1 + 0.9;
    dy *= (Math.random() - 0.5) * 0.1 + 0.9;

    const finalX = x + dx * this.duration;
    const finalY = y + dy * this.duration;

    const animation = this.el.animate([
      { 
        transform: `translate3d(${x}px, ${y}px, 0)`,
        opacity: '0.8',
      },
      { 
        transform: `translate3d(${finalX}px, ${finalY}px, 0)`,
        opacity: '0.05',
      }
    ], {
      duration: this.duration,
      iterations: 1,
    });
    animation.onfinish = () => this.hide();
  }
}