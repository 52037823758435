export interface Size {
  width: number;
  height: number;
}

export interface Location {
  x: number;
  y: number;
}

export interface Rectangle {
  readonly left: number;
  readonly right: number;
  readonly top: number;
  readonly bottom: number;
}

export function createRectangle(data: {width: number, height: number, center: Location}) {
  const halfWidth = data.width * 0.5;
  const halfHeight = data.height * 0.5;
  return {
    left: data.center.x - halfWidth,
    right: data.center.x + halfWidth,
    top: data.center.y - halfHeight,
    bottom: data.center.y + halfHeight,
  }
}

export function areRetanglesEqual(obj1: Rectangle, obj2: Rectangle): boolean {
  return obj1.left === obj2.left &&
      obj1.right === obj2.right &&
      obj1.top === obj2.top &&
      obj1.bottom === obj2.bottom;
}

export function isRectangle(val: Object): val is Rectangle {
  const rect = val as Rectangle;
  return rect.left !== undefined
      && rect.right !== undefined
      && rect.top !== undefined
      && rect.bottom !== undefined
}

export interface LocationAndTime extends Location {
  time: DOMHighResTimeStamp;
}

export function isObject(val: unknown): val is Object {
  return Boolean(val && typeof val === 'object' && !Array.isArray(val))
}