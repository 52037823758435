import { Rectangle, Location } from "src/util/types";

export interface HorizontalLine {
  readonly type: 'horizontal-line';
  y: number;
  left: number;
  dleft: number;
  right: number;
  dright: number;
}

export interface VerticalLine {
  readonly type: 'vertical-line';
  x: number;
  top: number;
  dtop: number;
  bottom: number;
  dbottom: number;
}

export interface Circle {
  readonly type: 'circle';
  dx: number;
  dy: number;
  x: number;
  y: number;
  radius: number;
  dradius: number;
}

export type Line = VerticalLine|HorizontalLine;

export function isLine(val: CollisionTarget): val is Line {
  return val.type === 'horizontal-line' || val.type === 'vertical-line';
}

export type CollisionTarget = Circle|Line;

function locationToCircle(loc: Location): Circle {
  return {
    type: 'circle',
    dx: 0,
    dy: 0,
    x: loc.x,
    y: loc.y,
    radius: 1,
    dradius: 0,
  }
}

export function cornersToCircles(rectangle: Rectangle): Circle[] {
  return [
    {x: rectangle.left + 0.5, y: rectangle.top + 0.5},
    {x: rectangle.right - 0.5, y: rectangle.top + 0.5},
    {x: rectangle.left + 0.5, y: rectangle.bottom - 0.5},
    {x: rectangle.right - 0.5, y: rectangle.bottom - 0.5},
  ].map(locationToCircle);
}