import { assert } from "./assert";
import { Location, LocationAndTime } from "./types";

export const IS_SAFARI = /apple/i.test(navigator.vendor);

export function setShown(el: string|HTMLElement|HTMLElement[], show: boolean) {
  if (typeof el === 'string' || el instanceof String) {
    const realEl = getById(el as string);
    assert(realEl, 'Unable to find element with id '+ el);
    setShown(realEl, show);
    return;
  }
  if (el instanceof Array) {
    for (const e of el) {
      setShown(e, show);
    }
  } else {
    (el as HTMLElement).style.display = show ? '' : 'none';
  }
}

export function isShown(el: HTMLElement): boolean {
  return el.style.display !== 'none';
}

export function querySelectorAll<T extends HTMLElement>(query: string): T[] {
  return Array.from(document.querySelectorAll(query)) as T[];
}

export function querySelector<T extends HTMLElement>(query: string): T {
  return document.querySelector(query) as T;
}

export function getById<T extends HTMLElement>(id: string): T {
  return document.getElementById(id) as T;
}

export function isTouchEvent(event: MouseEvent|TouchEvent): event is TouchEvent {
  return !!((event as TouchEvent).touches);
}

export function getMouseEventOrTouch(event: MouseEvent|TouchEvent, touchId?: number): MouseEvent|Touch|undefined {
  if (touchId !== undefined) {
    if (!isTouchEvent(event)) return undefined;
    return Array.from(event.changedTouches).find(t => t.identifier === touchId);
  }
  if (!isTouchEvent(event)) return event;
  return undefined;
}

export function getPositionAndTime(eventOrTouch: MouseEvent|Touch, originalEvent: MouseEvent|TouchEvent): LocationAndTime {
  return {x: eventOrTouch.clientX, y: eventOrTouch.clientY, time: originalEvent.timeStamp};
}

function listenToMultipleEvents(target: Node, boundFn: any, eventNames: string[]) {
  for (const eventName of eventNames) {
    target.addEventListener(eventName, boundFn);
  }
}

export function addMouseDownListener(target: Node, boundFn: Function) {
  listenToMultipleEvents(target, boundFn, ['mousedown', 'touchstart']);
}

export function addMouseUpListener(target: Node, boundFn: Function) {
  listenToMultipleEvents(target, boundFn, ['mouseup', 'touchend']);
}

export function addMouseMoveListener(target: Node, boundFn: Function) {
  listenToMultipleEvents(target, boundFn, ['mousemove', 'touchmove']);
}

type TransformConfig = {scale?: number, translate?: Location};

export function transform(el: HTMLElement, transforms: TransformConfig) {
  const existing = ((el as any)._transforms || {}) as TransformConfig;
  transforms = {...existing, ...transforms};
  (el as any)._transforms = transforms;
  let style = '';
  const {scale, translate} = transforms;
  if (translate) {
    style += ` translate3d(${translate.x}px,${translate.y}px,0)`;
  }
  if (scale) {
    style += ` scale3d(${scale},${scale},1)`;
  }
  if (!style) {
    style = 'translate3d(0,0,0)';
  }
  el.style.transform = style;
}

export function setCssProperty(el: HTMLElement, name: string, value: string|number) {
  el.style.setProperty(name, value.toString());
}

export function setCssPropertyWithPx(el: HTMLElement, name: string, value: number) {
  setCssProperty(el, name, value);
  setCssProperty(el, name + '-px', value + 'px');
}