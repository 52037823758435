export const HALF_PI = Math.PI * 0.5;

export function rad2deg(rad: number): number {
  return Math.round(rad / Math.PI * 180);
}

/**
 * Returns a value between -Pi and Pi where positve/negative indicates direction.
 * Assumes rads have been normalized.
 */
export function diffAngles(rad1:number, rad2: number): number {
  let diffAngle = Math.abs(rad1 - rad2);
  if (diffAngle > Math.PI) {
    diffAngle = Math.PI * 2 - diffAngle;
  }
  return diffAngle * (rad1 < rad2 ? -1 : 1);
}

/**
 * Assumes rads have been normalized.
 */
export function areAnglesEqual(rad1: number, rad2: number, range = 0.01): boolean {
  return Math.abs(diffAngles(rad1, rad2)) < range;
}

/**
 * Assumes rads have been normalized.
 */
export function isAngleWithin(rad: number, limit1: number, limit2: number): boolean {
  while (limit1 < 0 || limit2 < 0) {
    rad = normalizeRad(rad + Math.PI * 0.5);
    limit1 = normalizeRad(limit1 + Math.PI * 0.5);
    limit2 = normalizeRad(limit2 + Math.PI * 0.5);
  }
  const low = limit1 < limit2 ? limit1 : limit2;
  const high = limit1 < limit2 ? limit2 : limit1;
  return rad >= low && rad <= high;
}

/**
 * Returns a number between -Pi and Pi
 */
export function normalizeRad(rad: number): number {
  while (rad < 0) rad += Math.PI * 2;
  rad = (rad % (Math.PI * 2));
  if (rad > Math.PI) {
    rad = rad - Math.PI * 2;
  }
  return rad;
}

export function quadraticFormula(a: number, b: number, c: number): [number, number]|undefined {
  if (a === 0) return undefined;
  const sqrt = Math.sqrt(sq(b) - 4*a*c);
  if (isNaN(sqrt)) return undefined;
  return [
    (-1*b + sqrt) / (2 * a),
    (-1*b - sqrt) / (2 * a),
  ]
}

/**
 * Squares a number.
 */
export function sq(a: number): number {
  return a*a;
}

export function sign(a: number): 0|1|-1 {
  if (a === 0) return 0;
  return a < 0 ? -1 : 1;
}

export function clamp(value: number, min: number, max: number): number {
  return Math.min(max, Math.max(min, value));
}

export function maybeRoundToZero(value: number, minValue: number): number {
  return Math.abs(value) < minValue ? 0 : value;
}

export function valueAlongScale(from: number, to: number, percent: number): number {
  if (isNaN(percent)) percent = 1;
  percent = Math.min(1, (Math.max(0, percent)));
  return (to - from) * percent + from;
}

export function randomFloat(min: number, max: number): number {
  return Math.random() * (max - min) + min;
}