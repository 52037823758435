export class CircularArray<T> {
  private readonly content = new Array<T>(this.maxLength);
  private nextI = 0;
  private length_ = 0;

  constructor(readonly maxLength: number) {
    this.maxLength = maxLength;
  }

  push(val: T) {
    this.content[this.nextI] = val;
    if (this.length_ < this.maxLength) {
      this.length_++;
    }
    this.nextI = (this.nextI + 1) % this.maxLength;
  }

  get length(): number {
    return this.length_;
  }

  last(): T|undefined {
    if (this.length_ === 0) {
      return undefined;
    }
    const i = (this.nextI - 1 + this.maxLength) % this.maxLength;
    return this.content[i];
  }

  first(): T|undefined {
    if (this.length_ === 0) {
      return undefined;
    }
    const i = this.length_ < this.maxLength ? 0 : this.nextI;
    return this.content[i];
  }

  clear() {
    this.length_ = 0;
  }

  values(): T[] {
    return this.content.slice(this.nextI).concat(this.content.slice(0, this.nextI));
  }
}
