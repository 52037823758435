import { maybeRoundToZero } from "../util/math";
import {Location} from "../util/types";

interface VelocityAndAngle {
  velocity: number;
  angle: number;
}

function isLocation(value: Location|VelocityAndAngle): value is Location {
  return (value as Location).x !== undefined;
}

// Due to trig conversions, values can end really close to 0,
// when we mean for them to be 0. This leads to all kinds of problems up stream.
const MIN_VALUE = 0.000001;

export class Vector {
  private value: Location|VelocityAndAngle;

  constructor(x: number, y: number) {
    this.value = {x, y};
  }

  get velocity(): number {
    const {value} = this;
    if (isLocation(value)) {
      return Math.sqrt(value.x * value.x + value.y * value.y);
    } else {
      return value.velocity;
    }
  }

  set velocity(val: number) {
    const {angle} = this;
    this.value = {
      velocity: val,
      angle,
    };
  }

  get angle(): number {
    const {value} = this;
    if (isLocation(value)) {
      return Math.atan2(value.y, value.x);
    } else {
      return value.angle;
    }
  }

  set angle(rad: number) {
    const {velocity} = this;
    this.value = {
      velocity,
      angle: rad,
    };
  }

  get x(): number {
    const {value} = this;
    if (isLocation(value)) {
      return value.x;
    } else {
      return maybeRoundToZero(Math.cos(value.angle) * value.velocity, MIN_VALUE);
    }
  }

  set x(val: number) {
    const {y} = this;
    this.value = {
      x: val,
      y,
    };
  }

  get y(): number {
    const {value} = this;
    if (isLocation(value)) {
      return value.y;
    } else {
      return maybeRoundToZero(Math.sin(value.angle) * value.velocity, MIN_VALUE);
    }
  }

  set y(val: number) {
    const {x} = this;
    this.value = {
      x,
      y: val,
    };
  }
}