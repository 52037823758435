import { checkExhaustive } from "../util/assert";
import { Ball } from "./ball";
import { getEnvironmentState } from "./environment";
import { PageTransformer } from "./page_transformer";
import { Rectangle } from "../util/types";
import { forEachPair } from "../util/arrays";
import { areCirclesGoingToCollide } from "./collision_finder";
import { randomFloat } from "../util/math";

const FIX_BUFFER = 1;

/**
 * Makes sure none of the balls are out of bounds.
 */
export function validateBallPositions(balls: Ball[], pageTransformer: PageTransformer|null) {
  const {screenSize} = getEnvironmentState();
  balls = balls.filter(b => !b.disabled);
  // TODO - handle running page transformers.
  if (pageTransformer && !pageTransformer.isFullyOpen) {
    pageTransformer = null;
  }

  // First make sure it's not off screen.
  for (const ball of balls) {
    moveInsideScreen(ball);

    // Now make sure we're not inside of a PageTransformer
    if (pageTransformer) {
      moveOutsidePageTransformner(ball, pageTransformer);
    }
  }

  // Now make sure no balls are overlapping.
  // Bounds describes where to put the ball randomly if it's collided.
  const bounds: Rectangle = {
    top: 0,
    // Avoid overlapping with at least some of the navigation.
    left: 120,
    right: screenSize.width,
    // If there's a PageTranformer open, just stick it above since there should be plenty
    // of room up there.
    bottom: pageTransformer ? pageTransformer.rectangle.top : screenSize.height,
  };
  let overlapping = findOverlappingBall(balls);
  while (overlapping) {
    const diameter = overlapping.radius * 2;
    setLeft(overlapping, 
        randomFloat(bounds.left, bounds.right - diameter));
    setTop(overlapping,
        randomFloat(bounds.top, bounds.bottom - diameter));
    overlapping.dx = 0;
    overlapping.dy = 0;
     
    overlapping = findOverlappingBall(balls);
  }
}

function findOverlappingBall(balls: Ball[]): Ball|undefined {
  return forEachPair(balls, (ball1, ball2) => {
    if (ball1.isGrabbed && ball2.isGrabbed) return;
    if (areCirclesGoingToCollide(ball1, ball2, 0)) {
      return ball1.isGrabbed ? ball2 : ball1;
    }
  });
}

function moveInsideScreen(ball: Ball) {
  const {screenSize} = getEnvironmentState();
  if (ball.left < 0) {
    setLeft(ball, 0);
  } else if (ball.right > screenSize.width) {
    setRight(ball, screenSize.width);
  }
  if (ball.top < 0) {
    setTop(ball, 0);
  } else if (ball.bottom > screenSize.height) {
    setBottom(ball, screenSize.height);
  }
}

function moveOutsidePageTransformner(ball: Ball, pageTransformer: PageTransformer) {
  const rect = pageTransformer.rectangle;
  let isInside = false;
  if (ball.x >= rect.left && ball.x <= rect.right &&
      ball.bottom > rect.top && ball.top < rect.bottom) {
    // Inside a horizontal wall.
    isInside = true;
  } else if (ball.y >= rect.top && ball.y <= rect.bottom &&
      ball.right > rect.left && ball.left < rect.right) {
    // Inside a vertical wall.
    isInside = true;
  }
  
  if (isInside) {
    // It's inside a wall, move it to the closest wall.
    let closestWall: 'left'|'right'|'top'|'bottom' = 'left';
    let closestDistance = Number.POSITIVE_INFINITY;

    const topDist = Math.abs(ball.y - rect.top);
    if (topDist < closestDistance) {
      closestWall = 'top';
      closestDistance = topDist;
    }
    const bottomDist = Math.abs(ball.y - rect.bottom);
    if (bottomDist < closestDistance) {
      closestWall = 'bottom';
      closestDistance = bottomDist;
    }
    const leftDist = Math.abs(ball.x - rect.left);
    if (leftDist < closestDistance) {
      closestWall = 'left';
      closestDistance = leftDist;
    }
    const rightDist = Math.abs(ball.x - rect.right);
    if (rightDist < closestDistance) {
      closestWall = 'right';
      closestDistance = rightDist;
    }

    switch (closestWall) {
      case 'top':
        setBottom(ball, rect.top);
        break;
      case 'bottom':
        setTop(ball, rect.bottom);
          break;
      case 'left':
        setRight(ball, rect.left);
        break;
      case 'right':
        setLeft(ball, rect.right);
        break;
      default: checkExhaustive(closestWall);
    }
    return;
  }

  // Now check the corners.
  if (isInsideBall(ball, rect.left, rect.top)) {
    const adjustment = getBallSquareAdjustment(ball);
    setBottom(ball, rect.top + adjustment);
    setRight(ball, rect.left + adjustment);
    return;
  }
  if (isInsideBall(ball, rect.right, rect.top)) {
    const adjustment = getBallSquareAdjustment(ball);
    setBottom(ball, rect.top + adjustment);
    setLeft(ball, rect.right - adjustment);
    return;
  }
  if (isInsideBall(ball, rect.left, rect.bottom)) {
    const adjustment = getBallSquareAdjustment(ball);
    setTop(ball, rect.bottom - adjustment);
    setRight(ball, rect.left + adjustment);
    return;
  }
  if (isInsideBall(ball, rect.right, rect.bottom)) {
    const adjustment = getBallSquareAdjustment(ball);
    setTop(ball, rect.bottom - adjustment);
    setLeft(ball, rect.right - adjustment);
    return;
  }
}

const SQRT_OF_PI = Math.sqrt(Math.PI);

/**
 * The difference between the diameter and the diagnol of a square.
 * No idea what you would call this.
 */
function getBallSquareAdjustment(ball: Ball): number {
  return ball.radius / SQRT_OF_PI * 0.5;
}

function isInsideBall(ball: Ball, x: number, y: number) {
  const xDiff = ball.x - x;
  const yDiff = ball.y - y;
  return ball.radiusSquared >= xDiff * xDiff + yDiff * yDiff;
}

function setLeft(ball: Ball, pos: number) {
  ball.left = pos + FIX_BUFFER;
  ball.unmoveableX = 0;
}

function setRight(ball: Ball, pos: number) {
  ball.right = pos - FIX_BUFFER;
  ball.unmoveableX = 0;
}

function setTop(ball: Ball, pos: number) {
  ball.top = pos + FIX_BUFFER;
  ball.unmoveableY = 0;
}

function setBottom(ball: Ball, pos: number) {
  ball.bottom = pos - FIX_BUFFER;
  ball.unmoveableY = 0;
}