import { Size, Location } from "../util/types";
import { clamp, valueAlongScale } from "../util/math";
import { setCssPropertyWithPx } from "../util/dom";
import { PageConfig } from "./page";

export type ScreenSize = Size & { diagnol: number };

const MIN_WIDTH = 340;
const MIN_HEIGHT = 600;

// Firefox mobile changes screen size on keybaord open and that can't
// be disabled with navigator.virtualKeyboard.
const isAndroidMobile = navigator.userAgent.includes('Android');
const SCREEN_CENTER_CUTOFF = 850;

function calculateScreenSize(): ScreenSize {
  const newSize = {
    width: window.innerWidth,
    height: window.innerHeight,
  };
  // If the Firefox Mobile keyboard opens, ignore that new height.
  if (isAndroidMobile && newSize.width === actualScreenSize.width
      && newSize.height < actualScreenSize.height) {
    newSize.height = actualScreenSize.height;
  }
  // Apply the min width/height. In browsers where a scrollbar will appear, we have to account for that.
  let appliedMinWidth = false;
  if (newSize.width < MIN_WIDTH) {
    newSize.width = MIN_WIDTH;
    newSize.height -= scrollbarWidth;
    appliedMinWidth = true;
  }
  if (newSize.height < MIN_HEIGHT) {
    newSize.width -= scrollbarWidth;
    newSize.height = MIN_HEIGHT - (appliedMinWidth ? scrollbarWidth : 0);
  }
  newSize.width = Math.max(newSize.width, MIN_WIDTH);
  newSize.height = Math.max(newSize.height, MIN_HEIGHT);
  return {
    ...newSize,
    diagnol: Math.sqrt(newSize.width * newSize.width + newSize.height * newSize.height),
  };
}

export interface EnvironmentState {
  readonly screenSize: ScreenSize;
  readonly screenCenter: Location;
  readonly maxPageSize: Size;
  readonly maxBallRadius: number;
  readonly miniBallScale: number;
  // The amount the width and height were changed during the last resize.
  readonly resizeScale: Size;
}

// Initialized in init.
let scrollbarWidth: number;
let actualScreenSize: Size;
let state: EnvironmentState;

export function getEnvironmentState() {
  return state;
}

function updateState(screenSize: ScreenSize, prev: ScreenSize) {
  const screenCenter = getScreenCenter(screenSize);
  const {maxBallRadius, miniBallScale} = getBallSizing(screenSize, screenCenter);
  const maxPageSize = getMaxPageSize(screenSize, maxBallRadius, miniBallScale);
  const resizeScale = {
    width: screenSize.width / prev.width,
    height: screenSize.height / prev.height,
  }
  state = {
    screenSize, screenCenter, maxPageSize, maxBallRadius, miniBallScale, resizeScale
  }
  setGlobalCssSizes();
}

const resizeListeners: Array<(screenSize: EnvironmentState) => void> = [];

export function addScreenSizeListener(fn: (screenSize: EnvironmentState) => void) {
  resizeListeners.push(fn);
}

function getScreenCenter(screenSize: ScreenSize): Location {
  if (screenSize.width < SCREEN_CENTER_CUTOFF) {
    return {
      x: screenSize.width * 0.5,
      y: screenSize.height * 0.5 + 40,
    };
  }
  return {
    x: screenSize.width * 0.5,
    y: screenSize.height * 0.5,
  };
}

function getMaxPageSize(screenSize: ScreenSize, maxBallRadius: number, miniBallScale: number): Size {
  return {
    height: getPageHeight(screenSize),
    width: Math.floor(screenSize.width - (maxBallRadius * miniBallScale) * 4 - 10),
  }
}

function getPageHeight(screenSize: ScreenSize): number {
  return Math.floor(Math.pow(screenSize.height, 0.98) - 130);
}

function getBallSizing(screenSize: ScreenSize, screenCenter: Location) {
  const minDimension = Math.min(screenSize.width, screenSize.height);
  const bigPercent = clamp((minDimension - MIN_WIDTH) / 700, 0, 1);
  const maxBallRadius = valueAlongScale(38, 110, bigPercent);

  // Make enough room to fit along the sides
  let miniBallSize = Math.floor((screenSize.width - MIN_WIDTH) / 10 + 10);

  if (screenSize.width < SCREEN_CENTER_CUTOFF) {
    // Guard against the scenario where the ball is too tall for the bottom of the page.
    // This can happen when we push the page down, but the screen isn't small enough
    // for the sides to become the limiting factor.
    const pageHeight = getPageHeight(screenSize);
    const spaceAtBottom = screenSize.height - screenCenter.y - pageHeight * 0.5;
    if (miniBallSize * 2 + 5 > spaceAtBottom) {
      miniBallSize = Math.floor(spaceAtBottom / 2 - 5);
    }
  } else {
    
  }
  miniBallSize = clamp(miniBallSize, 10, 45);
  return {
    maxBallRadius,
    miniBallScale: miniBallSize / maxBallRadius,
  };
}

export function getBallRadius(config: PageConfig): {small: number, big: number} {
  const big = state.maxBallRadius * config.radiusScale;
  const small = state.miniBallScale * big;
  return {small, big};
}

function setGlobalCssSizes() {
  setBodyProp('--screen-width', state.screenSize.width);
  setBodyProp('--screen-height', state.screenSize.height);

  setBodyProp('--screen-center-x', state.screenCenter.x);
  setBodyProp('--screen-center-y', state.screenCenter.y);

  setBodyProp('--max-page-width', state.maxPageSize.width);
  setBodyProp('--max-page-height', state.maxPageSize.height);
}

function setBodyProp(name: string, value: number) {
  setCssPropertyWithPx(document.body, name, value);
}

function init() {
  // Calculate scrollbar width.
  {
    const div = document.createElement('div');
    div.style.width = '100px';
    div.style.height = '100px';
    div.style.overflow = 'scroll';
    document.body.appendChild(div);
    scrollbarWidth = div.offsetWidth - div.clientWidth;
    document.body.removeChild(div);
  }

  actualScreenSize = {
    width: window.innerWidth,
    height: window.innerHeight,
  };
  const screenSize = calculateScreenSize();
  updateState(screenSize, screenSize);

  window.addEventListener('resize', () => {
    const prev = state.screenSize;
    const screenSize = calculateScreenSize();
    actualScreenSize = {
      width: window.innerWidth,
      height: window.innerHeight,
    };
    if (prev.width !== screenSize.width 
        || prev.height !== screenSize.height) {
      updateState(screenSize, prev);
      for (const listener of resizeListeners) {
        listener(state);
      }
    }
  });
}
init();