import { CustomAnimation, easeInOutQuad, easeOutCubic } from "../util/animation";
import { Page } from "./page";

/**
 * The tiny bullet that appears next to the navigation links.
 */
export class Bullet {
  private el = document.querySelector(`li.nav-${this.page} .bullet`) as HTMLElement;
  private currentAnimation?: CustomAnimation;
  private transformAnimation?: CustomAnimation; 

  private isCurrent = false;

  constructor(readonly page: Page) {}

  setCurrent() {
    if (this.isCurrent) return;
    this.isCurrent = true;
    this.currentAnimation?.stop();
    this.currentAnimation = new CustomAnimation({
      el: this.el,
      duration: 250,
      easingFunction: easeOutCubic,
      cssVariable: '--current-percent',
      forward: true,
      fromZero: (el) => {
        el.style.transform = '';
      }
    });
  }

  removeCurrent() {
    if (!this.isCurrent) return;
    this.isCurrent = false;
    this.currentAnimation?.stop();
    this.currentAnimation = new CustomAnimation({
      el: this.el,
      duration: 200,
      easingFunction: easeOutCubic,
      cssVariable: '--current-percent',
      forward: false,
      atZero: (el) => {
        // Hide it off screen.
        el.style.transform = 'translate3d(-500px,0,0)';
      }
    })
  }

  startTransform(duration: number) {
    this.runTransformAnimation(duration, true);
  }

  reverseTransform(duration: number) {
    this.runTransformAnimation(duration, false);
  }

  private runTransformAnimation(duration: number, forward: boolean) {
    const startTime = this.transformAnimation ?
        duration - this.transformAnimation.timePassed : 0;
    this.transformAnimation?.stop();
    this.transformAnimation = new CustomAnimation({
      el: this.el,
      duration,
      startTime,
      easingFunction: easeInOutQuad,
      cssVariable: '--transform-percent',
      forward,
    });
  }
}