import { setCssProperty } from "./dom";
import { clamp } from "./math";

export interface AnimationContructorArgs {
  readonly el: HTMLElement;
  readonly duration: number;
  readonly easingFunction: (n: number) => number;
  readonly cssVariable: string;
  // When true, move the cssVariable from 0 to 1.
  // Otherwise, move it from 1 to 0.
  readonly forward: boolean;
  // A value other than 0 to start at.
  readonly startTime?: number;
  readonly atZero?: (el: HTMLElement) => void;
  readonly fromZero?: (el: HTMLElement) => void;
}

export class CustomAnimation {
  private readonly boundRenderLoop = this.renderLoop.bind(this);
  lastTime = performance.now();
  timePassed = this.args.startTime ?? 0;
  private stopped = false;
  private percent = -1;

  constructor(readonly args: AnimationContructorArgs) {
    this.updateCss();
    requestAnimationFrame(this.boundRenderLoop);
  }

  stop() {
    this.stopped = true;
  }

  private renderLoop() {
    if (this.stopped) return;
    const now = performance.now()
    this.timePassed += now - this.lastTime;
    this.lastTime = now;

    this.updateCss();
    if (this.timePassed < this.args.duration) {
      requestAnimationFrame(this.boundRenderLoop);
    }
  }

  private updateCss() {
    const wasZero = this.percent === 0;
    this.calculatePercent();
    setCssProperty(this.args.el, this.args.cssVariable, this.percent);
    if (this.percent === 0 && this.args.atZero) {
      this.args.atZero(this.args.el);
    } else if (wasZero && this.args.fromZero) {
      this.args.fromZero(this.args.el);
    }
  }

  private calculatePercent() {
    let percent = this.timePassed / this.args.duration;
    percent = clamp(percent, 0, 1);
    if (!this.args.forward) {
      percent = 1 - percent;
    }
    this.percent = this.args.easingFunction(percent);
  }
}

export function easeInOutQuad(x: number): number {
  return x < 0.5 ? 2 * x * x : 1 - Math.pow(-2 * x + 2, 2) / 2;
}

export function easeOutCubic(x: number): number {
  return 1 - Math.pow(1 - x, 3);
}